import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';

import type {
  PPDataStoreDebtContextProps,
  PPDataStoreOfferContextProps,
} from '@haaretz/s-use-pp-data-store';
import type { ParsedUrlQuery } from 'querystring';

export interface UtmHtmProps {
  htm_source?: string | string[];
  htm_medium?: string | string[];
  htm_campaign?: string | string[];
  htm_content?: string | string[];
  utm_source?: string | string[];
  utm_medium?: string | string[];
  utm_campaign?: string | string[];
  utm_content?: string | string[];
}
export interface CommonPaymentParameters
  extends PPDataStoreOfferContextProps,
    PPDataStoreDebtContextProps,
    UtmHtmProps {
  couponId?: string | null;
  personalCouponId?: string | null;
}

export default function getUtmQueryParameters(query: ParsedUrlQuery) {
  const {
    htm_source,
    htm_medium,
    htm_campaign,
    htm_content,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
  } = query;

  const utmParams = discardNullableValues({
    utmCampaign: htm_campaign || utm_campaign,
    utmContent: htm_content || utm_content,
    utmMedium: htm_medium || utm_medium,
    utmSource: htm_source || utm_source,
  });
  return utmParams;
}
