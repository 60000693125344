import browserStorageGetItem from '@haaretz/s-browser-storage-utils/getItem';
import browserStorageSetItem from '@haaretz/s-browser-storage-utils/setItem';
import { USER_ASSISTANCE } from '@haaretz/s-consts';
import React from 'react';

import type { UserAssistance } from '@haaretz/s-types';

export default function useAssistanceNotification() {
  const [showAssistanceNotification, setShowAssistanceNotification] = React.useState(false);

  React.useEffect(() => {
    const userAssistance = browserStorageGetItem({ key: USER_ASSISTANCE, storage: 'local' });

    if (userAssistance) {
      const parsedUserAssistance = JSON.parse(userAssistance) as UserAssistance;

      if (parsedUserAssistance.count >= 2 && !parsedUserAssistance.bannerDisplayed) {
        setShowAssistanceNotification(true);

        browserStorageSetItem({
          key: USER_ASSISTANCE,
          storage: 'local',
          value: {
            ...parsedUserAssistance,
            bannerDisplayed: true,
          },
        });
      }
    }
  }, []);

  return { showAssistanceNotification, setShowAssistanceNotification };
}
