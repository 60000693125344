import useUser from '@haaretz/s-atoms/user';
import fetchChangePlan from '@haaretz/s-pp-build-promotions-link/fetchChangePlan';
import getUtmQueryParameters from '@haaretz/s-pp-build-promotions-link/getUtmQueryParameters';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import getFirstStep from '@haaretz/s-pp-routing-utils/getFirstStep';
import getNextStep from '@haaretz/s-pp-routing-utils/getNextStep';
import gtagAddToCart from '@haaretz/s-pp-selected-offer-utils/gtagAddToCart';
import savePurchasePreferences from '@haaretz/s-pp-selected-offer-utils/savePurchasePreferences';
import useFreeProduct from '@haaretz/s-pp-use-coupon/useFreeProduct';
import usePersonalFreeCoupon from '@haaretz/s-pp-use-coupon/usePersonalFreeCoupon';
import useRegularFreeCoupon from '@haaretz/s-pp-use-coupon/useRegularFreeCoupon';
import isCoupon from '@haaretz/s-type-predicates/isCoupon';
import isFreeProductOrCoupon from '@haaretz/s-type-predicates/isFreeProductOrCoupon';
import isPersonalCoupon from '@haaretz/s-type-predicates/isPersonalCoupon';
import useBi from '@haaretz/s-use-bi';
import useConfig from '@haaretz/s-use-config';
import { usePPDataStoreActions } from '@haaretz/s-use-pp-data-store';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React from 'react';

import type {
  BiDataOverrides,
  SelectedOfferProps,
  RequiredSelectedOfferProps,
} from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { PurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import type { PPDataStoreOfferContextProps } from '@haaretz/s-use-pp-data-store';

type usePpOfferCardOperationsProps = {
  couponId?: PageDataFragment['couponId'];
  personalCouponId?: PageDataFragment['personalCouponId'];
  offerData: RequiredSelectedOfferProps;
  steps: StepDataFragment[];
  onClickAndImpressionBiData: BiDataOverrides;
  offerType: SelectedOfferProps['offerType'];
};

export default function usePpOfferCardOperations({
  couponId,
  personalCouponId,
  offerData,
  steps,
  onClickAndImpressionBiData,
  offerType,
}: usePpOfferCardOperationsProps) {
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const config = useConfig();
  const variables = usePpDataVariables();
  const client = useQueryClient();
  const redeemPersonalFreeCoupon = usePersonalFreeCoupon();
  const redeemRegularFreeCoupon = useRegularFreeCoupon();
  const redeemFreeProduct = useFreeProduct();
  const router = useRouter();
  const biAction = useBi();
  const user = useUser('cookieValue');
  const { updateStore } = usePPDataStoreActions<PPDataStoreOfferContextProps>();

  const isLoggedIn = user?.isLoggedIn;

  const msPurchaseUrl = config.get('msPurchaseUrl');
  const enabledPpQuery = shouldRedirect && (!isLoggedIn || !!variables.selectedOfferId);

  const utmParams = React.useMemo(() => getUtmQueryParameters(router.query), [router.query]);

  React.useEffect(() => {
    if (enabledPpQuery) {
      (async () => {
        let ppData = client.getQueryData<PurchasePagePersonalQuery>(
          usePurchasePagePersonalQuery.getKey(variables)
        );

        /* istanbul ignore else */
        if (!ppData?.PurchasePage) {
          ppData = await client.fetchQuery<PurchasePagePersonalQuery>({
            queryKey: usePurchasePagePersonalQuery.getKey(variables),
            queryFn: usePurchasePagePersonalQuery.fetcher(variables),
          });
        }

        /* istanbul ignore next */
        const newSteps = ppData?.PurchasePage?.pageData.steps || [
          { name: 'offers', __typename: 'StepData' },
          { name: 'login', __typename: 'StepData' },
        ];

        const nextStepName = isLoggedIn
          ? getFirstStep(newSteps).name
          : getNextStep('offers', newSteps).nextStep.name;

        const coupons = { couponId, personalCouponId };

        if (isCoupon(coupons) && isFreeProductOrCoupon(offerData)) {
          if (isPersonalCoupon(coupons)) {
            redeemPersonalFreeCoupon({
              ...offerData,
              ...utmParams,
              personalCouponId: coupons.personalCouponId,
              msPurchaseUrl,
              nextStepName,
            });
          } else {
            redeemRegularFreeCoupon({
              ...offerData,
              ...utmParams,
              couponId: coupons.couponId,
              msPurchaseUrl,
              nextStepName,
            });
          }
        } else if (offerType === 'upgrade') {
          try {
            const response = await fetchChangePlan({ msPurchaseUrl, ...offerData });

            const data = await response.json();

            if (data.success) {
              const { pathName, asPath } = generatePath({
                nextStepName,
                asPath: router.asPath,
                currentQueryParams: router.query,
              });

              router.replace(pathName, asPath, { shallow: true });
            } else {
              router.push('/error');
            }
          } catch (err) {
            console.error((err as Error).message);
            router.push('/error');
          }
        } else if (isFreeProductOrCoupon(offerData)) {
          redeemFreeProduct({
            ...offerData,
            ...utmParams,
            msPurchaseUrl,
            nextStepName,
          });
        } else {
          const { pathName, asPath } = generatePath({
            nextStepName,
            asPath: router.asPath,
            currentQueryParams: router.query,
          });

          router.push(pathName, asPath, { shallow: true });
        }
      })();

      setShouldRedirect(false);
    }
  }, [
    client,
    couponId,
    enabledPpQuery,
    isLoggedIn,
    msPurchaseUrl,
    offerData,
    offerType,
    personalCouponId,
    redeemFreeProduct,
    redeemPersonalFreeCoupon,
    redeemRegularFreeCoupon,
    router,
    utmParams,
    variables,
  ]);

  const { nextStep } = getNextStep('offers', steps);

  const { asPath } = generatePath({
    nextStepName: nextStep.name,
    asPath: router.asPath,
    currentQueryParams: router.query,
  });

  const onClick = (
    event: React.MouseEvent<HTMLElement>,
    biDataExtend?: BiDataOverrides | undefined
  ) => {
    event.preventDefault();

    biAction({ ...onClickAndImpressionBiData, ...biDataExtend });

    gtagAddToCart({ offerData, couponId });

    savePurchasePreferences({ isLoggedIn, offerData, couponId, msPurchaseUrl });

    updateStore({ contentId: offerData.contentId });
    setShouldRedirect(true);
  };

  return {
    onClick,
    enabledPpQuery,
    asPath,
  };
}
