import color from '@haaretz/l-color.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import RichText from '@haaretz/s-rich-text';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import * as React from 'react';
import s9 from 'style9';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const c = s9.create({
  base: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: space(6),

    ...mq({ from: 's', value: { marginBottom: space(7) } }),
  },
  line1: {
    color: color('primary1000'),
  },
  line1Price: {
    ...typesetter(5, { lines: 8 }),
  },
  bold: {
    fontWeight: 700,
  },
});

interface PriceDetailsProps {
  /**
   * the first line of the purchase button
   */
  line1: PurchaseOfferFragment['line1Text'];
  /**
   * the second line of the purchase button
   */
  line2: PurchaseOfferFragment['line2Text'];
}

export default function PriceDetails({ line1: [line1], line2 }: PriceDetailsProps) {
  if (!isHtmlNode(line1)) return null;

  const { content: line1Content } = line1;
  const line2Content = line2?.[0] && isHtmlNode(line2[0]) ? line2[0].content : null;
  // TODO: receive from props if ab test is successful, needs to be developed in backend
  const [line1ContentPart1, line1ContentPart2] = line1Content.split(' / ');

  return (
    <div className={s9(c.base)}>
      {/* TODO: Change to receive as props instead of hard coded */}
      <div className={s9(c.line1)}>
        <RichText as="span" content={line1ContentPart1} styleExtend={[c.line1Price]} />
        {line1ContentPart2 ? (
          <>
            {/* punctuation space before and after */}
            <span className={s9(c.bold)}>&#8200;/&#8200;</span>
            <RichText as="span" content={line1ContentPart2} styleExtend={[c.bold]} />
          </>
        ) : null}
      </div>
      {line2Content ? <RichText as="span" content={line2Content} /> : null}
    </div>
  );
}
