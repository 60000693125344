import fork from '@haaretz/l-fork.macro';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import isValidSelectedOffer from '@haaretz/s-type-predicates/isValidSelectedOffer';

import type { SelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PPDataStoreDebtContextProps } from '@haaretz/s-use-pp-data-store';

export interface UtmHtmProps {
  utmSource?: string | string[];
  utmMedium?: string | string[];
  utmCampaign?: string | string[];
  utmContent?: string | string[];
}
export interface CommonPaymentParameters
  extends PPDataStoreDebtContextProps,
    SelectedOfferProps,
    UtmHtmProps {
  couponId?: string | null;
  personalCouponId?: string | null;
}

export default function getCommonParameters(props: CommonPaymentParameters) {
  if (!isValidSelectedOffer(props)) {
    throw new Error('Offer not selected');
  }

  const {
    productNumber,
    promotionNumber,
    saleCode,
    thankYouMailTemplate,
    approveDebtClaim,
    contentId,
  } = props;

  const optionalParams = {
    utmCampaign: props.utmCampaign,
    utmContent: props.utmContent,
    utmMedium: props.utmMedium,
    utmSource: props.utmSource,
    couponId: props.couponId,
    personalCouponId: props.personalCouponId,
  };

  const params = new URLSearchParams(
    discardNullableValues({
      contentId,
      productId: productNumber?.toString(),
      saleCode: saleCode?.toString(),
      promotionNumber: promotionNumber?.toString(),
      approveDebtClaim: String(approveDebtClaim === true),
      thankYouEmailTemplate: thankYouMailTemplate,
      cgtype: fork({ default: 'heb', hdc: 'eng' }),
    })
  );

  (Object.keys(optionalParams) as (keyof typeof optionalParams)[]).forEach(name => {
    const value = optionalParams[name];

    if (value) {
      params.append(name, value.toString());
    }
  });

  return params;
}
