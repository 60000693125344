import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import Icon from '@haaretz/s-icon';
import s9 from 'style9';

import type { StyleExtend } from '@haaretz/s-types';

const iconSize = space(9);

const c = s9.create({
  htzIcon: {
    fontSize: iconSize,
    color: color('htzBrand'),
  },
  bothHtzIconMargin: {
    marginInlineEnd: space(-3.5),
  },
  tmIcon: {
    fontSize: iconSize,
    color: color('tmBrand'),
  },
});

export function BothLogo({
  htzStyleExtend = [],
  tmStyleExtend = [],
}: {
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  htzStyleExtend?: StyleExtend;
  tmStyleExtend?: StyleExtend;
}) {
  return (
    <>
      <Icon icon="alef" styleExtend={[c.htzIcon, c.bothHtzIconMargin, ...htzStyleExtend]} />
      <Icon icon="m" styleExtend={[c.tmIcon, ...tmStyleExtend]} />
    </>
  );
}

export function HtzLogo() {
  return <Icon icon="alef" variant="brand" styleExtend={[c.htzIcon]} />;
}

export function TmLogo() {
  return <Icon icon="m" variant="success" styleExtend={[c.tmIcon]} />;
}
