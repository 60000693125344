import getCommonParameters from '../getCommonParameters';
import getPurchaseServiceLink from '../getPurchaseServiceLink';

import type { CommonPaymentParameters } from '../getCommonParameters';

type GetAbandonedCartLinkProps = CommonPaymentParameters & {
  msPurchaseUrl: string;
};

export default function getAbandonedCartLink({
  msPurchaseUrl,
  ...props
}: GetAbandonedCartLinkProps) {
  const searchParams = getCommonParameters(props);

  return getPurchaseServiceLink({
    msPurchaseUrl,
    searchParams,
    endpoint: 'savePurchasePreferences',
  });
}
