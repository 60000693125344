'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import useUser from '@haaretz/s-atoms/user';
import BlockLink from '@haaretz/s-block-link';
import Card from '@haaretz/s-card';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import usePpOfferCardOperations from '@haaretz/s-use-pp-offer-card-operations';
import * as React from 'react';
import s9 from 'style9';

import CancellableLine from './components/CancellableLine';
import CornerStrip from './components/CornerStrip';
import OfferBenefits from './components/OfferBenefits';
import OfferButton from './components/OfferButton';
import OfferNotice from './components/OfferNotice';
import OfferTitle from './components/OfferTitle';

import type { BiDataOverrides, RequiredSelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';
import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  base: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: space(100),
    position: 'relative',
    width: '100%',
    overflow: 'visible',
    ...merge(
      {
        ...mq({
          until: 'm',
          value: {
            paddingTop: space(6),
            paddingBottom: space(6),
            paddingInlineStart: space(6),
            paddingInlineEnd: space(6),
          },
        }),
      },
      {
        ...mq({
          from: 'm',
          value: {
            paddingTop: space(8),
            paddingBottom: space(8),
            paddingInlineStart: space(11),
            paddingInlineEnd: space(11),
          },
        }),
      }
    ),
  },
  notice: {
    position: 'absolute',
    top: space(3),
  },
});

export interface OffersCardProps {
  benefits?: PurchaseOfferFragment['benefits'];
  benefitsHeading?: PurchaseOfferFragment['benefitsHeading'];
  contracted?: boolean;
  couponId?: PageDataFragment['couponId'];
  exclusive?: PurchaseOfferFragment['exclusive'];
  exclusiveStrip?: PurchaseOfferFragment['exclusiveStrip'];
  legalText: PurchaseOfferFragment['legalText'];
  line1Text: PurchaseOfferFragment['line1Text'];
  line2Text?: PurchaseOfferFragment['line2Text'];
  note?: PurchaseOfferFragment['note'];
  offerData: RequiredSelectedOfferProps;
  offerType: PurchaseOfferFragment['offerType'];
  personalCouponId?: PageDataFragment['personalCouponId'];
  product: PurchaseOfferFragment['product'];
  productName: PurchaseOfferFragment['productName'];
  steps: StepDataFragment[];
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
}

const sentImpressions: string[] = [];

export default function OfferCard({
  benefits,
  benefitsHeading,
  biData,
  contracted,
  couponId,
  exclusive,
  exclusiveStrip,
  inlineStyle,
  legalText,
  line1Text,
  line2Text,
  note,
  offerData,
  offerType,
  personalCouponId,
  product,
  productName,
  steps,
  styleExtend = [],
}: OffersCardProps) {
  const user = useUser('cookieValue');
  const offerCardRef = React.useRef<HTMLAnchorElement>(null);

  const onClickAndImpressionBiData: BiDataOverrides = {
    ...biData,
    prod_no: offerData.productNumber,
    sale_code: offerData.saleCode,
    promotions_no: offerData.promotionNumber,
    campaign_details: `title: ${productName}${
      isHtmlNode(line1Text[0])
        ? `, button: ${line1Text[0].content} ${
            line2Text?.[0] && isHtmlNode(line2Text[0]) ? line2Text[0].content : ''
          }`
        : ''
    }`,
  };

  const { asPath, enabledPpQuery, onClick } = usePpOfferCardOperations({
    offerData,
    onClickAndImpressionBiData: {
      ...onClickAndImpressionBiData,
      action_id: 21,
    },
    steps,
    couponId,
    personalCouponId,
    offerType,
  });

  const impressionKey = offerData.contentId + (user?.userMail || '');

  useImpressionObserver({
    disabled: sentImpressions.includes(impressionKey),
    elementRef: offerCardRef,
    biData: onClickAndImpressionBiData,
    onObserve: () => {
      sentImpressions.push(impressionKey);
    },
  });
  return (
    <Card
      elevationLevel={1}
      inlineStyle={inlineStyle}
      styleExtend={[c.base, ...styleExtend]}
      data-testid="offer-card"
      id={offerData.contentId}
    >
      {exclusiveStrip ? <CornerStrip exclusiveStrip={exclusiveStrip} /> : null}
      {exclusive && <OfferNotice styleExtend={[c.notice]}>{exclusive}</OfferNotice>}
      <OfferTitle product={product} productName={productName} />
      <OfferButton
        line1={line1Text}
        line2={
          offerType === 'upgrade' && note
            ? [{ as: 'p', __typename: 'HtmlNode', content: note }]
            : line2Text
        }
        onClick={onClick}
        state={enabledPpQuery ? 'busy' : undefined}
      />
      <CancellableLine
        legalText={legalText}
        biData={biData}
        onTermsButtonClick={onClick}
        loading={enabledPpQuery}
      />
      <OfferBenefits
        items={benefits}
        heading={benefitsHeading}
        contracted={contracted}
        biData={
          biData && {
            ...biData,
            ...(isHtmlNode(line1Text[0])
              ? {
                  campaign_details: `${`button: ${line1Text[0].content} ${
                    line2Text?.[0] && isHtmlNode(line2Text[0]) ? line2Text[0].content : ''
                  }`}`,
                }
              : {}),
          }
        }
      />
      <BlockLink
        aria-hidden
        tabIndex={-1}
        href={asPath}
        shallow
        onClick={onClick}
        ref={offerCardRef}
      >
        {''}
      </BlockLink>
    </Card>
  );
}
