/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const c = s9.create({
  base: {
    position: 'absolute',
    top: space(-1.15),
    insetInlineStart: space(-1.25),
    ...merge(
      mq({
        until: 's',
        value: {
          top: space(-1.4),
          insetInlineStart: space(-1.3),
        },
      })
    ),
  },
  cornerStrip: {
    fontSize: '5.2rem',
    height: '1em',
    width: '1em',
  },
  text: {
    position: 'absolute',
    color: color('neutral100'),
    width: '100%',
    top: space(4.75),
    insetInlineStart: space(-2.75),
    transform: 'rotate(45deg)',
    fontWeight: 'bold',
    textAlign: 'center',
    ...typesetter(-2),
    ...merge(
      mq({
        from: 'xl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
});

interface CornerStripProps {
  exclusiveStrip: PurchaseOfferFragment['exclusiveStrip'];
}

export default function CornerStrip({ exclusiveStrip }: CornerStripProps) {
  return (
    <div className={s9(c.base)}>
      <svg className={s9(c.cornerStrip)} focusable={false} tabIndex={-1}>
        <use xlinkHref="#offer-corner-strip" />
      </svg>
      <span className={s9(c.text)}>{exclusiveStrip}</span>
    </div>
  );
}
