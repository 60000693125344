import color from '@haaretz/l-color.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const iconSize = space(9);

const c = s9.create({
  base: {
    ...typesetter(2),

    ...mq({ from: 'xl', value: { ...typesetter(1) } }),
    color: color('neutral1200'),
    display: 'flex',
    alignItems: 'center',
  },
  haaretzIcon: {
    fontSize: iconSize,
    color: color('htzBrand'),
  },
  theMarkerIcon: {
    fontSize: iconSize,
    color: color('tmBrand'),
  },
  logoBothPosition: {
    marginInlineEnd: space(-3.5),
  },
});

export interface OfferTitleProps {
  product: PurchaseOfferFragment['product'];
  productName: PurchaseOfferFragment['productName'];
}

export default function OfferTitle({ product, productName }: OfferTitleProps) {
  const iconByLogo =
    product === 'HTZ' ? (
      <Icon icon="alef" variant="brand" styleExtend={[c.haaretzIcon]} />
    ) : product === 'TM' ? (
      <Icon icon="m" variant="success" styleExtend={[c.theMarkerIcon]} />
    ) : (
      <>
        <Icon icon="alef" styleExtend={[c.haaretzIcon, c.logoBothPosition]} />
        <Icon icon="m" styleExtend={[c.theMarkerIcon]} />
      </>
    );

  return (
    <h2 className={s9(c.base)} data-testid="offer-title">
      {iconByLogo}
      {productName}
    </h2>
  );
}
