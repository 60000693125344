'use client';

import fontStack from '@haaretz/l-font-stack.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import Dropdown from '@haaretz/s-dropdown';
import HtzImage from '@haaretz/s-htz-image';
import Icon from '@haaretz/s-icon';
import NoSSR from '@haaretz/s-no-ssr';
import PpModal from '@haaretz/s-pp-modal';
import TextField from '@haaretz/s-text-field';
import TextLink from '@haaretz/s-text-link';
import useBi from '@haaretz/s-use-bi';
import useConfig from '@haaretz/s-use-config';
import React from 'react';
import s9 from 'style9';

import Circles from './components/Circles';
import countryCodes from './utils/countryCodes';

import type { DropdownProps, Option } from '@haaretz/s-dropdown';
import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { HtzImageProps } from '@haaretz/s-htz-image';
import type { PPMessageProps } from '@haaretz/s-types';

const c = s9.create({
  base: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  astronautImg: {
    width: space(36.75),
    marginTop: space(-15),
    alignSelf: 'center',
  },
  successAstronautImg: {
    width: space(52.25),
    alignSelf: 'center',

    ...mq({ from: 'xl', value: { width: space(62), marginTop: space(5) } }),
  },
  ppModal: {
    overflow: 'visible',
    ...fork({
      default: {},
      hdc: {
        paddingTop: space(9),
        paddingBottom: space(11),
        paddingRight: space(3),
        paddingLeft: space(3),
        display: 'flex',
        alignItems: 'center',
        minHeight: space(112),

        ...merge(
          mq({
            from: 's',
            value: {
              paddingTop: space(21),
              paddingBottom: space(21),
              minHeight: space(136),
            },
          }),
          mq({
            from: 's',
            until: 'l',
            value: {
              paddingRight: space(15),
              paddingLeft: space(15),
            },
          }),
          mq({
            from: 'l',
            value: {
              width: space(150),
              paddingRight: space(25),
              paddingLeft: space(25),
            },
          })
        ),
      },
    }),
  },
  title: {
    marginTop: space(fork({ default: 3, hdc: 0 })),
    display: fork({ default: 'flex', hdc: 'block' }),
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: space(1),
    ...typesetter(2),
    fontFamily: fontStack('primary'),
    fontWeight: fork({ default: 700, hdc: 400 }),
    textAlign: 'center',
    marginBottom: space(fork({ default: 0, hdc: 1 })),

    ...mq({ from: 'xl', value: { ...typesetter(fork({ default: 1, hdc: 2 })) } }),
  },
  warnIcon: {
    fontSize: space(6),
    alignSelf: 'flex-start',
    verticalAlign: '-0.1em',

    ...mq({ from: 'xxl', value: { fontSize: space(7) } }),
  },
  text: {
    marginBottom: space(fork({ default: 5, hdc: 10 })),
    textAlign: 'center',
    ...typesetter(1),

    ...mq({ from: 'xl', value: { ...typesetter(fork({ default: -1, hdc: 0 })) } }),
  },
  successTitle: {
    marginTop: space(fork({ default: 3, hdc: 0 })),
    ...typesetter(3),
    marginBottom: space(fork({ default: 0, hdc: 3 })),

    ...mq({
      from: 'xl',
      value: { ...typesetter(2), marginTop: space(fork({ default: 6, hdc: 0 })) },
    }),
  },
  successText: {
    marginBottom: space(fork({ default: 19, hdc: 0 })),
    paddingRight: space(fork({ default: 0, hdc: 8 })),
    paddingLeft: space(fork({ default: 0, hdc: 8 })),
    ...typesetter(2),

    ...mq({
      from: 's',
      value: {
        paddingRight: space(fork({ default: 0, hdc: 3 })),
        paddingLeft: space(fork({ default: 0, hdc: 3 })),
      },
    }),
    ...mq({ from: 'xl', value: { ...typesetter(0) } }),
  },
  phone: {
    marginBottom: space(5),
  },
  dropdownsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: space(6),
    columnGap: space(3),
  },
  dropdown: {
    width: '50%',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: space(4),
    columnGap: space(4),
  },
  hdcDayDropdown: {
    marginBottom: space(5),
    maxWidth: space(50),
  },
  countryCodeDropdown: {
    marginBottom: space(5),
  },
});

interface AssistanceModalProps {
  isOpen: boolean;
  onToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

const type: ImageFragment['type'] = 'image';
const alt = 'alt';
const widths: number[] = [204, 147];
const aspectRatio: ImageAspect = 'full';

const AstronautImage = () => {
  const config = useConfig();
  const env = config.get('env');

  const imgData: ImageFragment['files'][number] = {
    __typename: 'ImageViewModel_ImageFile',
    crops: { __typename: 'ImageCrops' },
    width: 264,
    height: 216,
    path:
      env === 'prod'
        ? fork({
            default: 'c2/65/eff52e7e41d7b7329fed97fe8996/astornaut-assist-users.png',
          })
        : fork({
            default: 'c9/54/7386e3d34efcae49fd687625263b/astornaut-assist-users.png',
          }),
  };

  const contentId =
    env === 'prod'
      ? fork({
          default: '00000189-ba30-d9f3-a1cd-bfbb50d10001',
        })
      : fork({
          default: '00000189-ba31-de40-a9d9-ff75c2a20000',
        });

  const imgProps: HtzImageProps = {
    __typename: 'htz_image_Image',
    imgData,
    type,
    alt,
    contentId,
    widths,
    aspect: aspectRatio,
  };

  return <HtzImage {...imgProps} styleExtend={[c.astronautImg]} />;
};

const widthsSuccess: number[] = [248, 209];

const SuccessAstronautImage = () => {
  const config = useConfig();
  const env = config.get('env');

  const imgData: ImageFragment['files'][number] = {
    __typename: 'ImageViewModel_ImageFile',
    crops: { __typename: 'ImageCrops' },
    width: 197,
    height: 151,
    path:
      env === 'prod'
        ? fork({
            default: 'ba/94/d486416f48a598a3b663806c7423/asrtro-success.png',
          })
        : fork({
            default: '05/4d/b2ae9efe463aae30fd1fef90b0ee/asrtro-success.png',
          }),
  };

  const contentId =
    env === 'prod'
      ? fork({
          default: '00000189-d94e-d0f3-a7ab-fdde458c0001',
        })
      : fork({
          default: '00000189-d94f-d150-a9bf-d9cfdfd50001',
        });

  const imgProps: HtzImageProps = {
    __typename: 'htz_image_Image',
    imgData,
    type,
    alt,
    contentId,
    widths: widthsSuccess,
    aspect: aspectRatio,
  };

  return <HtzImage {...imgProps} styleExtend={[c.successAstronautImg]} />;
};

const daysOfWeek = [
  fork({ default: 'ראשון', hdc: 'Sunday' }),
  fork({ default: 'שני', hdc: 'Monday' }),
  fork({ default: 'שלישי', hdc: 'Tuesday' }),
  fork({ default: 'רביעי', hdc: 'Wednesday' }),
  fork({ default: 'חמישי', hdc: 'Thursday' }),
];

const getValidDays: () => Option[] = () => [
  { text: daysOfWeek[0], value: daysOfWeek[0] },
  { text: daysOfWeek[1], value: daysOfWeek[1] },
  { text: daysOfWeek[2], value: daysOfWeek[2] },
  { text: daysOfWeek[3], value: daysOfWeek[3] },
  { text: daysOfWeek[4], value: daysOfWeek[4] },
];

const validHours: Option[] = [
  { value: '8:00-10:00', text: '8:00-10:00' },
  { value: '10:00-12:00', text: '10:00-12:00' },
  { value: '12:00-14:00', text: '12:00-14:00' },
  { value: '14:00-16:00', text: '14:00-16:00' },
];

type FormStage = 'default' | 'success' | 'error';
type DropdownSharedProps = Pick<DropdownProps, 'variant' | 'styleExtend'>;

const defaultTexts = {
  title: fork({ default: 'היי, באנו לעזור!', hdc: 'We’re here to help!' }),
  text: fork({
    default: 'נציגי השירות שלנו ישמחו לסייע טלפונית בשעה שמתאימה לך',
    hdc: 'Our representatives would be happy to assist you',
  }),
};

const defaultPhoneText = fork({
  default: 'אנא הזינו מספר טלפון',
  hdc: 'Please enter a valid phone number',
});
const submitBtnText = fork({ default: 'שלח', hdc: 'Submit' });
const cancelBtnText = fork({ default: 'אמשיך לבד', hdc: 'No Thanks' });

const AssitanceModal = React.forwardRef(function AssitanceModal(
  { isOpen, onToggle }: AssistanceModalProps,
  ref: React.ForwardedRef<HTMLDialogElement>
) {
  const [selectedDay, setSelectedDay] = React.useState<number | undefined>(undefined);
  const [selectedHour, setSelectedHour] = React.useState<number | undefined>(undefined);
  const [selectedCountryCode, setSelectedCountryCode] = React.useState<number | undefined>(
    undefined
  );
  const config = useConfig();
  const msPurchaseUrl = config.get('msPurchaseUrl');
  const labelledBy = React.useId();
  const [formStage, setFormStage] = React.useState<FormStage>('default');
  const [texts, setTexts] =
    React.useState<Record<'title' | 'text', string | React.JSX.Element>>(defaultTexts);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState(defaultPhoneText);
  const isSuccessStage = formStage === 'success';
  const phoneRef = React.useRef<HTMLInputElement | null>(null);
  const biAction = useBi();
  const [isInvalidCountryCodes, setIsInvalidCountryCodes] = React.useState(!!selectedCountryCode);

  const onClose = () => {
    if (onToggle) onToggle(false);
  };

  const onChangeDay = React.useCallback(() => {
    setSelectedHour(undefined);
  }, []);

  const onChangeCountryCodes = React.useCallback((value: number | undefined) => {
    if (value != null) setIsInvalidCountryCodes(false);
  }, []);

  const daysToShow = React.useMemo(() => getDaysToShow(), []);
  const hoursToShow = fork({
    default: selectedDay != null ? getHoursToShow(daysToShow[selectedDay]) : validHours,
    hdc: [],
  });

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (selectedCountryCode == null) setIsInvalidCountryCodes(true);

    const dateToCall =
      selectedDay != null &&
      (!daysToShow[selectedDay].value
        ? null
        : convertDayToDate({ daysToShow, selectedDay, withYear: true }).replaceAll('.', '-'));
    const phoneValue =
      fork({
        default: phoneRef.current?.value,
        hdc:
          selectedCountryCode != null &&
          phoneRef.current?.value &&
          countryCodes[selectedCountryCode].value + phoneRef.current?.value,
      }) || null;

    if (phoneValue) {
      setIsLoading(true);
      const hoursToCall =
        selectedHour != null && hoursToShow[selectedHour].value
          ? ` ${hoursToShow[selectedHour].value}`
          : '';
      const timeToCall = dateToCall ? `${dateToCall}${hoursToCall}` : null;
      const assistanceLink = `${msPurchaseUrl}/helper/purchaseAssistance`;

      biAction({
        action_id: 185,
        feature: 'Promotions help',
        feature_type: 'Marketing',
        campaign_name: 'help tool form clicked',
        campaign_details: submitBtnText,
      });

      try {
        const res = await fetch(assistanceLink, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            phoneNumber: phoneValue,
            timeToCall,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await res.json();
        const { title, instructions } =
          ((await data.responseMessageDetails) as PPMessageProps) || {};
        const decodedTexts = getDecodedTexts(title, instructions);
        setTexts(decodedTexts);

        if (data.success) {
          setFormStage('success');
        } else if (res.status >= 300) {
          throw new Error('go to catch');
        } else {
          setFormStage('error');
        }
      } catch {
        setTexts({
          title: fork({
            default: 'אירעה שגיאה, אנא נסו שנית.',
            hdc: 'Oops! An error occurred Please try again',
          }),
          text: fork<string | React.JSX.Element>({
            default: 'אם הבעיה חוזרת, פנו לשירות הלקוחות',
            hdc: (
              <>
                If the problem persists, please contact{' '}
                <TextLink href="https://www.haaretz.com/contact-us">customer service</TextLink>
              </>
            ),
          }),
        });
        setFormStage('error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const submitBtnDisabled = selectedDay == null && selectedHour != null;

  const dropdownSharedProps: DropdownSharedProps = {
    variant: 'neutral',
    styleExtend: [c.dropdown],
  };

  return (
    <PpModal
      isOpen={isOpen}
      onToggle={onToggle}
      closeBtnOnClick={onClose}
      labelledBy={labelledBy}
      styleExtend={[isOpen && c.ppModal]}
      closeBtnStyle={fork({ default: 'neutral', hdc: 'blueCircle' })}
      ref={ref}
      data-testid="assistance-modal"
    >
      <form className={s9(c.base)} onSubmit={onSubmit}>
        {isSuccessStage
          ? fork({ default: <SuccessAstronautImage />, hdc: <Circles /> })
          : fork({ default: <AstronautImage />, hdc: null })}
        <h2 id={labelledBy} className={s9(c.title, isSuccessStage && c.successTitle)}>
          {formStage === 'error' && (
            <Icon icon="warn" variant="danger" styleExtend={[c.warnIcon]} />
          )}
          {texts.title}
        </h2>
        <span className={s9(c.text, isSuccessStage && c.successText)}>{texts.text}</span>
        {isSuccessStage ? null : (
          <>
            {fork({
              default: null,
              hdc: (
                <Dropdown
                  required
                  label="Country Code"
                  variant="neutral"
                  options={countryCodes}
                  onChange={onChangeCountryCodes}
                  description="Please select a country code"
                  isInvalid={isInvalidCountryCodes}
                  controlledComponent={{
                    state: selectedCountryCode,
                    setState: setSelectedCountryCode,
                  }}
                  styleExtend={[c.countryCodeDropdown]}
                />
              ),
            })}
            <TextField
              type="tel"
              label={fork({ default: 'טלפון', hdc: 'Phone' })}
              required
              name="phone"
              styleExtend={[c.phone]}
              pattern={fork({ default: '^0\\d{8,9}$', hdc: '^(0\\d{1,2}-)?\\d{6,12}$' })}
              maxLength={fork({ default: 10, hdc: 12 })}
              onInput={() => setErrorPhone(defaultPhoneText)}
              ref={phoneRef}
              onInvalid={e => {
                e.preventDefault();
                setErrorPhone(
                  fork({
                    default: 'מספר הטלפון שהוזן אינו תקין',
                    hdc: 'Invalid phone number',
                  })
                );
              }}
              isInvalid={errorPhone !== defaultPhoneText}
              description={errorPhone}
            />
            <div className={s9(fork({ default: c.dropdownsWrapper, hdc: null }))}>
              <Dropdown
                label={fork({ default: 'יום', hdc: 'Best day to call' })}
                {...fork({
                  default: dropdownSharedProps,
                  hdc: { variant: 'neutral', styleExtend: [c.hdcDayDropdown] },
                })}
                onChange={onChangeDay}
                options={daysToShow}
                controlledComponent={{
                  state: selectedDay,
                  setState: setSelectedDay,
                }}
              />
              {fork({
                default: (
                  <Dropdown
                    {...dropdownSharedProps}
                    label="שעה"
                    options={hoursToShow}
                    controlledComponent={{
                      state: selectedHour,
                      setState: setSelectedHour,
                    }}
                    disabled={selectedDay == null}
                  />
                ),
                hdc: null,
              })}
            </div>
            <div className={s9(c.buttonsWrapper)}>
              <Button
                priority="tertiary"
                type="button"
                onClick={() => {
                  biAction({
                    action_id: 0,
                    feature: 'Promotions help',
                    feature_type: 'Marketing',
                    campaign_name: 'help tool form closed',
                    campaign_details: cancelBtnText,
                  });
                  onClose();
                }}
                data-testid="assist-modal-decline-btn"
              >
                {cancelBtnText}
              </Button>
              <Button
                type="submit"
                state={submitBtnDisabled || isLoading ? 'disabled' : 'auto'}
                data-testid="assist-modal-submit-btn"
              >
                {submitBtnText}
              </Button>
            </div>
          </>
        )}
      </form>
    </PpModal>
  );
});

const AssitanceModalWrapper = React.forwardRef(function AssitanceModalWrapper(
  props: AssistanceModalProps,
  ref: React.ForwardedRef<HTMLDialogElement>
) {
  return (
    <NoSSR>
      <AssitanceModal {...props} ref={ref} />
    </NoSSR>
  );
});

export default AssitanceModalWrapper;

///////////////
//  HELPERS  //
///////////////

function getHoursToShow(selectedDay: Option): Option[] {
  const date = new Date();
  const currentDay = date.getDay();
  const currentHour = date.getHours();
  const daysOptionsWithValue = getValidDays().filter(day => day.value);

  if (daysOptionsWithValue.findIndex(({ value }) => value === selectedDay.value) !== currentDay) {
    return validHours;
  }

  const hoursToShow = [];

  for (const hour of validHours) {
    if (hour.value) {
      const hourAsNumber = parseInt(hour.value.split('-')[1].slice(0, 2));

      if (currentHour < hourAsNumber) {
        hoursToShow.push(hour);
      }
    }
  }

  return hoursToShow.length > 0 ? hoursToShow : validHours;
}

const todayText = fork({ default: 'היום', hdc: 'Today' });
const tomorrowText = fork({ default: 'מחר', hdc: 'Tomorrow' });

function getDaysToShow() {
  let daysToShow = sortDays(getValidDays());
  const date = new Date();

  const shouldShowNextDay = getShouldShowNextDay(date);

  if (shouldShowNextDay) {
    const today = daysToShow.shift() as Option;
    daysToShow.push(today);

    daysToShow = daysToShow.map((day, index) => {
      if (index === 0) {
        day.text = tomorrowText;
        return day;
      }

      const dayAsDate = convertDayToDate({
        daysToShow,
        selectedDay: index,
        locales: fork({ default: 'he-IL', hdc: 'en-US' }),
      });
      day.text = `${day.text} (${dayAsDate})`;

      return day;
    });
  } else {
    daysToShow = daysToShow.map((day, index) => {
      if (index === 0) {
        day.text = todayText;
        return day;
      }

      if (index === 1) {
        day.text = tomorrowText;
        return day;
      }

      const dayAsDate = convertDayToDate({
        daysToShow,
        selectedDay: index,
        locales: fork({ default: 'he-IL', hdc: 'en-US' }),
      });
      day.text = `${day.text} (${dayAsDate})`;

      return day;
    });
  }

  return daysToShow;
}

function sortDays(daysToInclude: Option[]) {
  const daysOfWeekToSort = [...daysOfWeek];
  const today = new Date().getDay();
  for (let i = 0; i < today; i++) daysOfWeekToSort.push(daysOfWeekToSort.shift() as string);
  return daysOfWeekToSort.map(day => {
    const found = daysToInclude.find(({ value }) => day === value);
    return found as Option;
  });
}

function getShouldShowNextDay(date: Date) {
  const isHdc = fork({ default: false, hdc: true });
  if (isHdc) return true;

  const currentHour = date.getHours();

  const lastValidHourOfDay = parseInt(
    (validHours[validHours.length - 1].value as string).split('-')[1].slice(0, 2)
  );

  return currentHour >= lastValidHourOfDay;
}

type convertDayToDateParams = {
  daysToShow: Option[];
  selectedDay: number;
  withYear?: boolean;
  locales?: Intl.LocalesArgument;
};

function convertDayToDate({
  daysToShow,
  selectedDay,
  withYear,
  locales = 'he-IL',
}: convertDayToDateParams) {
  if (!daysToShow[selectedDay].value) return '';

  const actualDays = daysToShow.filter(({ value }) => value);
  const date = new Date();

  const startingDay = getShouldShowNextDay(date) ? selectedDay + 1 : selectedDay;

  const thursday = actualDays.findIndex(({ value }) => value === daysOfWeek[4]);
  if (selectedDay <= thursday) {
    date.setDate(date.getDate() + startingDay);
  } else {
    date.setDate(date.getDate() + startingDay + 2);
  }

  return date.toLocaleDateString(locales, {
    day: '2-digit',
    month: '2-digit',
    year: withYear ? 'numeric' : undefined,
  });
}

function getDecodedTexts(title: string, text: string) {
  return {
    title: decodeURIComponent(title),
    text: decodeURIComponent(text),
  };
}
