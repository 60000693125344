import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { BothLogo, HtzLogo, TmLogo } from '@haaretz/s-pp-logos';
import * as React from 'react';
import s9 from 'style9';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const c = s9.create({
  base: {
    textAlign: 'center',
    ...typesetter(2),
    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(1) } }),
      mq({ from: 'xxl', value: { ...typesetter(0) } })
    ),
    color: color('neutral1200'),
    marginBottom: space(3),
  },
});

export interface OfferTitleProps {
  product: PurchaseOfferFragment['product'];
  productName: PurchaseOfferFragment['productName'];
}

export default function OfferTitle({ product, productName }: OfferTitleProps) {
  const iconByLogo = product === 'HTZ' ? <HtzLogo /> : product === 'TM' ? <TmLogo /> : <BothLogo />;

  return (
    <h2 className={s9(c.base)} data-testid="offer-title">
      {iconByLogo}
      {productName}
    </h2>
  );
}
