import { useSetUser } from '@haaretz/s-atoms/user';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import { useRouter } from 'next/router';
import React from 'react';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { PPMessageProps } from '@haaretz/s-types';

export default function useFreeProductOrCoupon() {
  const router = useRouter();
  const updateUser = useSetUser();

  const reedemCoupon = React.useCallback(
    async (couponUrl: string, nextStepName: PurchasePageSteps) => {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      });

      try {
        const data = await fetch(couponUrl, {
          method: 'GET',
          credentials: 'include',
          headers,
        });

        const json = await (data.json() as Promise<PPMessageProps>);

        if (json.messageType?.startsWith('SUCCESSFUL_PURCHASE')) {
          const { pathName, asPath } = generatePath({
            nextStepName,
            asPath: router.asPath,
            currentQueryParams: router.query,
          });

          updateUser('login');

          router.push(pathName, asPath, { shallow: true });
        } else {
          const { pathName, asPath } = generatePath({
            nextStepName: 'error',
            asPath: router.asPath,
            currentQueryParams: router.query,
          });

          router.push(pathName, asPath, { shallow: true });
        }
      } catch (error) {
        console.error((error as Error).message);

        const { pathName, asPath } = generatePath({
          nextStepName: 'error',
          asPath: router.asPath,
          currentQueryParams: router.query,
        });

        router.push(pathName, asPath, { shallow: true });
      }
    },
    [router, updateUser]
  );

  return reedemCoupon;
}
