'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    position: 'fixed',
    zIndex: zIndex('alert'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: color('sales200'),
    width: `calc(100vw - ${space(6)} * 2)`,
    left: space(6),
    borderRadius: radius('xLarge'),
    opacity: 0,
    scale: 0.8,
    animationName: s9.keyframes({
      '0%': {
        opacity: 0,
        scale: 0.8,
      },
      // '50%': {
      //   scale: 0.8,
      // },
      '100%': {
        opacity: 1,
        scale: 1,
      },
    }),
    animationDuration: '0.3s',
    animationFillMode: 'forwards',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in-out',

    ...border({
      color: color('sales300'),
      width: 1,
      spacing: 3,
      style: 'solid',
      side: 'all',
    }),

    paddingInlineEnd: space(4),
    paddingInlineStart: space(4),

    ...mq({
      from: 'm',
      value: {
        width: 'initial',
        minWidth: space(150),
        left: '50%',
        translate: '-50%',
        paddingInlineEnd: space(6),
        paddingInlineStart: space(6),
      },
    }),
  },
  childrenWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    display: 'block',
  },
  top: {
    top: space(17),

    ...mq({ from: 'm', value: { top: space(21) } }),
  },
  bottom: {
    bottom: space(6),

    ...mq({ from: 'm', value: { bottom: space(10) } }),
  },
  center: {
    top: '50%',
  },
  inline: {
    position: 'static',
    width: 'max-content',
    maxWidth: '100%',

    ...mq({
      from: 'm',
      value: {
        translate: 0,
        left: 'initial',
        width: 'max-content',
      },
    }),
  },
  sales: {
    backgroundColor: color('sales200'),
  },
  danger: {
    backgroundColor: color('secondary700'),
  },
  startOfTransition: {
    opacity: 0,
    scale: 0.2,
    transitionDuration: '0.25s',
    transitionProperty: 'opacity, scale, translate',
    transitionTimingFunction: 'ease-in-out',
  },
  endOfTransition: {
    opacity: 1,
    translate: '0 0',
    scale: 1,
  },
  dismissTransition: {
    transitionProperty: 'opacity, translate',
    transitionDelay: '0.2s, 0,',
    transitionDuration: '0.5s',
    opacity: 0,
    translate: '100vw',

    ...mq({
      from: 'm',
      value: {
        translate: '100vw',
      },
    }),
  },
});

export interface NotificationProps {
  /** The Children to be rendered inside `<Notification>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Notification styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * Sets the color of the notification background
   */
  variant: 'sales' | 'danger';
  /**
   * Determines the urgency of the notification.
   * Use 'low' if it does not interrupt the user’s current task, 'high' if it does.
   *
   * @defaultValue 'low'
   */
  priority: 'low' | 'high';
  /**
   * Places the notification element relatively to the viewport.
   *
   * @defaultValue 'inline'
   */
  placement?: 'top' | 'center' | 'bottom' | 'inline';
  /**
   * Dismiss the notification automatically after X milliseconds.
   *
   */
  dismissAfter?: number;
  /**
   *  Controls whether the notification is open or closed.
   */
  isOpen: boolean;
  /**
   * A callback that is fired whenever the notification is opened or closed,
   * and takes an `isOpen` boolean argument indicating if the notification is open.
   */
  onClose: () => void;
}

const Notification = React.forwardRef(function Notification(
  {
    children,
    inlineStyle,
    styleExtend = [],
    isOpen,
    priority = 'low',
    dismissAfter,
    placement = 'inline',
    variant,
    onClose: onCloseFromProps,
  }: NotificationProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [startTransition, setStartTransition] = React.useState(false);
  const [endTransition, setEndTransition] = React.useState(false);

  const onClose = () => {
    setEndTransition(true);
  };

  const onTransitionEnd = () => {
    if (dismissAfter != null) {
      setTimeout(() => {
        setEndTransition(true);
      }, dismissAfter);
    }

    if (endTransition && onCloseFromProps) {
      onCloseFromProps();
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      setStartTransition(true);
    }
  }, [isOpen]);

  return isOpen ? (
    <div
      className={s9(
        c.base,
        c.startOfTransition,
        startTransition && c.endOfTransition,
        endTransition && c.dismissTransition,
        c[variant],
        c[placement],
        ...styleExtend
      )}
      ref={ref}
      data-testid="notification"
      style={inlineStyle}
      role={priority === 'high' ? 'alert' : 'status'}
      onTransitionEnd={onTransitionEnd}
    >
      <div className={s9(c.childrenWrapper)}>{children}</div>
      <ClickArea
        onClick={onClose}
        styleExtend={[c.closeBtn]}
        size="small"
        data-testid="notification-close-btn"
      >
        <Icon icon="close" styleExtend={[c.closeIcon]} />
      </ClickArea>
    </div>
  ) : null;
});

export default Notification;
