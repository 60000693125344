'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { ppDesignVersions } from '@haaretz/s-consts';
import RadioGroup from '@haaretz/s-radio-group';
import useBi from '@haaretz/s-use-bi';
import useRadioGroupState from '@haaretz/s-use-radio-group-state';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';

const lines = { lines: 4 };
const borderWidth = space(1);
const yearlyCheckedPosition = `calc(${borderWidth} / 2)`;
const monthlyCheckedPosition = `calc(50% + ${borderWidth} / 2)`;
const c = s9.create({
  base: {
    position: 'relative',
    backgroundColor: color('neutral300', { opacity: 0.8 }),
    opacity: 0.9,
    width: space(58),
    height: space(12),
    borderRadius: space(5),
    flexWrap: 'nowrap',
    ...typesetter(-1, lines),
    ...merge(mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2, lines) } })),
    ...merge(mq({ from: 'xxl', value: { ...typesetter(-3, lines) } })),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':before': {
      position: 'absolute',
      content: '""',
      borderRadius: space(5),
      width: `calc(50% - ${borderWidth})`,
      height: `calc(100% - ${borderWidth})`,
      backgroundColor: color('toggleCheckedBg'),
      top: `calc(${borderWidth} / 2)`,
      insetInlineStart: 'var(--default-checked-position)',
      animationDuration: '0.2s',
      animationDirection: 'normal',
      animationTimingFunction: 'linear',
      animationIterationCount: '1',
      animationFillMode: 'forwards',
    },
    ':hover': {
      backgroundColor: color('neutral400'),
      transitionProperty: 'background-color',
      transitionDuration: '300ms',
      transitionTimingFunction: 'ease-in-out',
    },
    ':focus-within': {
      outlineColor: `${color('neutral300')}`,
      outlineWidth: '2px',
      outlineStyle: 'solid',
      outlineOffset: '6px',
    },
  },
  toggleButton: {
    borderRadius: space(5),
    width: `calc(50% - ${borderWidth})`,
    height: `calc(100% - ${borderWidth})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  additionalText: {
    ...typesetter(-2, lines),
    ...merge(mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-3, lines) } })),
    ...merge(mq({ from: 'xxl', value: { ...typesetter(-4, lines) } })),
    color: color('quaternary800'),
    fontWeight: 700,
  },
  newAdditionalText: {
    color: color('neutral1200'),
  },
  moveCheckedToEnd: {
    ':before': {
      willChange: 'insent-inline-start',
      transformOrigin: fork({ default: 'left', hdc: 'right' }),
      animationName: s9.keyframes({
        '0%': { transform: 'scaleX(1)', insetInlineStart: `calc(${borderWidth} / 2)` },
        '60%': { transform: 'scaleX(1.5)' },
        '90%': { insetInlineStart: `calc(50% + ${borderWidth} / 2)` },
        '100%': { transform: 'scaleX(1)', insetInlineStart: `calc(50% + ${borderWidth} / 2)` },
      }),
    },
  },
  moveCheckedToStart: {
    ':before': {
      willChange: 'insent-inline-start',
      transformOrigin: fork({ default: 'right', hdc: 'left' }),
      animationName: s9.keyframes({
        '0%': { transform: 'scaleX(1)', insetInlineStart: `calc(50% + ${borderWidth} / 2)` },
        '60%': { transform: 'scaleX(1.5)' },
        '90%': { insetInlineStart: `calc(${borderWidth} / 2)` },
        '100%': { transform: 'scaleX(1)', insetInlineStart: `calc(${borderWidth} / 2)` },
      }),
    },
  },
  inputInvisble: {
    width: 0,
    height: 0,
  },
});

export type ValidValue =
  | readonly [string]
  | readonly [string, string | null | undefined]
  | undefined;
type BillingType = 'monthly' | 'yearly';

export type OfferToggleProps = {
  /**
   * Callback to control the billing period.
   */
  onChange: (billingPeriod: BillingType) => void;
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
  /** Design version of the page */
  designVersion?: PageDataFragment['designVersion'];
} & (
  | {
      /**
       * An object containing yearly array and monthly array, each of these contain
       * the texts of the options.
       * If the object contains only yearly or monthly, this component won't show
       * at all (it will return null).
       * The array with two values will be the default value.
       * Second value might contain additional text or not (undefined).
       **/
      yearly: readonly [string, string | null | undefined];
      monthly?: readonly [string];
    }
  | {
      yearly?: readonly [string];
      monthly: readonly [string, string | null | undefined];
    }
);

type BillingButtonProps = {
  text: ValidValue;
  value: BillingType;
  id: BillingType;
  defaultChecked: boolean;
  setCurrentlySelected: React.Dispatch<React.SetStateAction<string>>;
  biData?: BiDataOverrides;
  designVersion: PageDataFragment['designVersion'];
};

function BillingButton({
  value,
  id,
  defaultChecked,
  setCurrentlySelected,
  text,
  biData,
  designVersion,
}: BillingButtonProps) {
  const inputRef = React.useRef(null);
  const biAction = useBi();
  const newDesign = designVersion === ppDesignVersions.Version2;

  function onChangehandler() {
    setCurrentlySelected(id);
    biData &&
      biAction({
        ...biData,
        campaign_details: text && text[0],
        action_id: 25,
        feature: 'Promotions toggle',
        feature_type: 'Marketing',
      });
  }

  const { isChecked, name, onChange, tabIndex } = useRadioGroupState({
    checked: undefined,
    defaultChecked,
    input: inputRef.current,
    onChange: onChangehandler,
  });

  return (
    <label className={s9(c.toggleButton)}>
      <input
        checked={isChecked}
        onChange={onChange}
        tabIndex={tabIndex}
        name={name}
        value={value && value[0]}
        type="radio"
        ref={inputRef}
        className={s9(c.inputInvisble)}
        data-testid="pp-toggle-input"
      />
      <div className={s9(c.textWrapper)}>
        {text && text[1] && (
          <span className={s9(c.additionalText, newDesign && c.newAdditionalText)}>{text[1]}</span>
        )}
        <span>{text && text[0]}</span>
      </div>
    </label>
  );
}

export default function Toggle({
  monthly,
  yearly,
  onChange,
  biData,
  designVersion,
}: OfferToggleProps) {
  const defaultChecked: BillingType = monthly?.length === 2 ? 'monthly' : 'yearly';
  const [currentlySelected, setCurrentlySelected] = React.useState('');

  if (!(monthly && yearly)) {
    return null;
  }

  const getSharedProps = (type: BillingType) => ({
    setCurrentlySelected,
    biData,
    designVersion,
    value: type,
    id: type,
    defaultChecked: defaultChecked === type,
    text: type === 'monthly' ? monthly : yearly,
  });

  return (
    <RadioGroup
      onChange={onChange}
      aria-label={fork({ default: 'בחרו צורת חיוב', hdc: 'Select billing period' })}
      styleExtend={[
        c.base,
        currentlySelected === 'monthly' && c.moveCheckedToEnd,
        currentlySelected === 'yearly' && c.moveCheckedToStart,
      ]}
      inlineStyle={{
        '--default-checked-position':
          defaultChecked === 'yearly' ? yearlyCheckedPosition : monthlyCheckedPosition,
      }}
      groupName="billing-type"
      isUnstyled
      orientation="horizontal"
      data-testid="pp-toggle"
    >
      <>
        <BillingButton {...getSharedProps('yearly')} />
        <BillingButton {...getSharedProps('monthly')} />
      </>
    </RadioGroup>
  );
}
