import type { SelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PaymentType } from '@haaretz/s-types';

interface FreeCouponProps {
  paymentType: Extract<PaymentType, 'FREE'>;
}

export default function isFreeProductOrCoupon<T extends Pick<SelectedOfferProps, 'paymentType'>>(
  offer: T
): offer is T & FreeCouponProps {
  if (!(offer instanceof Object)) return false;

  return offer.paymentType === 'FREE';
}
