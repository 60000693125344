import getAbandonedCartLink from '@haaretz/s-pp-build-promotions-link/getAbandonedCartLink';

import type { RequiredSelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';

type SavePurchasePreferencesProps = {
  offerData: RequiredSelectedOfferProps;
  isLoggedIn: boolean;
  couponId?: PageDataFragment['couponId'];
  msPurchaseUrl: string;
};

export default function savePurchasePreferences({
  isLoggedIn,
  offerData,
  couponId,
  msPurchaseUrl,
}: SavePurchasePreferencesProps) {
  /* istanbul ignore else */
  if (isLoggedIn) {
    const link = getAbandonedCartLink({
      contentId: offerData.contentId,
      periodCalc: offerData.periodCalc,
      prices: offerData.prices,
      paymentType: offerData.paymentType,
      productNumber: offerData.productNumber,
      promotionNumber: offerData.promotionNumber,
      saleCode: offerData.saleCode,
      thankYouMailTemplate: offerData.thankYouMailTemplate,
      offerType: offerData.offerType,
      msPurchaseUrl,
      couponId,
    });

    fetch(link, { method: 'GET', credentials: 'include' });
  }
}
