'use client';

import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Button, { ButtonProps } from '@haaretz/s-button';
import s9 from 'style9';

export interface OfferButtonProps {
  onClick?: ButtonProps<'button'>['onClick'];
  state?: ButtonProps<'button'>['state'];
}

const c = s9.create({
  base: {
    marginBottom: space(3),
    zIndex: zIndex('above'),
  },
});

export default function OfferButton({ onClick, state }: OfferButtonProps) {
  return (
    <Button
      data-testid="offer-btn"
      variant="sales"
      onClick={onClick}
      styleExtend={[c.base]}
      {...(state === 'busy' ? { state, busyNotice: 'טוען...' } : { state })}
    >
      לרכישה
    </Button>
  );
}
