import getCommonParameters from '../getCommonParameters';
import getPurchaseServiceLink from '../getPurchaseServiceLink';

import type { CommonPaymentParameters } from '../getCommonParameters';

export type GetFreeProdutLinkProps = CommonPaymentParameters & {
  msPurchaseUrl: string;
};
export default function getFreeProductLink({
  msPurchaseUrl,
  couponId,
  personalCouponId,
  ...props
}: GetFreeProdutLinkProps) {
  const searchParams = getCommonParameters(props);

  return getPurchaseServiceLink({ msPurchaseUrl, searchParams, endpoint: 'okCoupon' });
}
