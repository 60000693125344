import getCurrentStep from '../getCurrentStep';

import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { PurchasePageSteps } from '@haaretz/s-types';
export default function getNextStep(currentStep: PurchasePageSteps, steps: StepDataFragment[]) {
  const { currentStepIndex } = getCurrentStep(currentStep, steps);
  const nextStepIndex = currentStepIndex + 1;

  if (nextStepIndex > steps.length - 1) {
    throw new Error('Invalid next step');
  }

  return { nextStep: steps[nextStepIndex], nextStepIndex };
}
