'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import AssitanceModal from '@haaretz/s-assistance-modal';
import Button from '@haaretz/s-button';
import { ppDesignVersions } from '@haaretz/s-consts';
import Notification from '@haaretz/s-notification';
import PPOfferCard, { OfferCardVersionTwo } from '@haaretz/s-offer-card';
import OfferLinks from '@haaretz/s-offer-links';
import useAssistanceNotification from '@haaretz/s-pp-assistance-utils/useAssistanceNotification';
import PPCoupon from '@haaretz/s-pp-coupon';
import PPHeader from '@haaretz/s-pp-header';
import PPImage from '@haaretz/s-pp-image';
import PPOfferToggle from '@haaretz/s-pp-offer-toggle';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import getCurrentStep from '@haaretz/s-pp-routing-utils/getCurrentStep';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { usePPOffsetStepsStoreActions } from '@haaretz/s-use-pp-offset-steps-store';
import React from 'react';
import s9 from 'style9';

import SpecialOffers from './components/SpecialOffers';

import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { ppQueryVariables } from '@haaretz/s-use-pp-data-variables';

const offersAnimationTime = 0.5;

const c = s9.create({
  coupon: {
    ...merge({
      ...mq({
        until: 'm',
        value: {
          paddingInlineStart: space(4),
          paddingInlineEnd: space(4),
          marginBottom: space(8),
        },
      }),
      ...mq({
        from: 'm',
        value: { marginBottom: space(6) },
      }),
    }),
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: space(7),
    marginBottom: space(6),
    ...mq({
      until: 'l',
      value: {
        marginTop: space(3),
      },
    }),
  },
  offers: {
    display: 'grid',
    animationDirection: 'forwards',
    animationDuration: `${offersAnimationTime}s`,
    animationIterationCount: 1,
    animationTimingFunction: 'linear',
    ...merge({
      ...mq({
        until: 'l',
        value: {
          gridTemplateColumns: '1fr',
          marginBottom: space(7),
          paddingInlineEnd: space(4),
          paddingInlineStart: space(4),
          rowGap: space(4),
          justifyItems: 'center',
        },
      }),
      ...mq({
        from: 'l',
        value: {
          columnGap: space(4),
          gridTemplateColumns: '1fr 1fr',
          marginBottom: space(8),
        },
      }),
    }),
  },
  newOffers: {
    ...merge({
      ...mq({
        until: 'l',
        value: {
          rowGap: space(5),
        },
      }),
      ...mq({
        from: 'l',
        value: {
          columnGap: space(5),
        },
      }),
    }),
  },
  oneOffer: {
    ...mq({
      from: 'l',
      value: {
        justifyItems: 'center',
        gridTemplateColumns: '1fr',
      },
    }),
  },
  offersWithoutToggle: {
    marginTop: space(8),
  },
  firstOffer: {
    ...merge({
      ...mq({
        from: 'l',
        value: { justifySelf: 'end' },
      }),
    }),
  },
  offersAnimation: {
    animationName: s9.keyframes({
      0: { transform: 'scale(1)', opacity: 1 },
      '25%': { opacity: 0 },
      '50%': { transform: 'scale(0.85)' },
      '75%': { opacity: 0 },
      '100%': { transform: 'scale(1)', opacity: 1 },
    }),
  },
  image: {
    alignSelf: 'end',
    display: 'block',
    fontSize: fork({
      default: space(56),
      tm: space(70),
    }),
    position: 'relative',
    marginBottom: fork({
      default: 'none',
      tm: space(-20),
    }),
    marginTop: fork({
      default: space(4),
      tm: 'none',
    }),
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    transform: fork({
      default: 'rotateY(180deg) translateX(-25%)',
      tm: 'none',
    }),

    ...merge(
      mq({
        from: 'l',
        until: 'xl',
        value: fork({
          default: {
            fontSize: space(72),
            marginTop: space(-44),
            transform: `rotateY(180deg) translateX(${space(-90)})`,
          },
          tm: {
            marginTop: space(-28),
            transform: `translateX(${space(65)})`,
            fontSize: space(100),
            marginBottom: space(-19),
          },
        }),
      }),
      mq({
        from: 'xl',
        value: {
          fontSize: fork({
            default: space(109),
            tm: space(100),
          }),
          marginInlineStart: 'auto',
          marginTop: fork({
            default: space(-90),
            tm: space(-70),
          }),
          transform: fork({
            default: 'translateX(-135%)',
            tm: 'translate(-90%, -6%) rotateY(180deg)',
          }),
        },
      })
    ),
  },

  notifText: {
    fontWeight: 700,

    ...typesetter(-2),

    ...mq({ from: 'm', value: { ...typesetter(-1) } }),
  },
  notifBtn: {
    ...typesetter(-2),
  },
});

const toggleKicketTitleAnnual = fork<string>({ default: 'מסלול שנתי', hdc: 'Annual' });
const toggleKicketTitleMonthly = fork<string>({ default: 'מסלול חודשי', hdc: 'Monthly' });

const currentStepName: StepDataFragment['name'] = 'offers';
const notificationText = 'אולי נוכל לסייע לך ברכישת המינוי?';

export default function OffersPage(): JSX.Element | null {
  const excludedVariables: (keyof Required<ppQueryVariables>)[] = React.useMemo(
    () => ['selectedOfferId'],
    []
  );

  const variables = usePpDataVariables(excludedVariables);

  const { data } = usePurchasePagePersonalQuery(variables);

  const [shouldAnimate, setShouldAnimate] = React.useState(false);
  const { clearSteps } = usePPOffsetStepsStoreActions();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const biAction = useBi();
  const notificationRef = React.useRef<HTMLDivElement>(null);
  const assistanceModalRef = React.useRef<HTMLDialogElement>(null);

  const pageData = data?.PurchasePage?.pageData;

  const [offersToShow, setOffersToShow] = React.useState<'annual' | 'monthly'>(
    pageData?.toggleKicker?.billing || 'annual'
  );

  useImpressionObserver({
    elementRef: notificationRef,
    biData: {
      feature: 'Promotions help',
      feature_type: 'Marketing',
      campaign_name: 'help tool shown',
      campaign_details: notificationText,
    },
  });

  useImpressionObserver({
    elementRef: assistanceModalRef,
    biData: {
      feature: 'Promotions help',
      feature_type: 'Marketing',
      campaign_name: 'help tool form shown',
    },
  });

  React.useEffect(() => {
    clearSteps();
  }, [clearSteps]);

  React.useEffect(() => {
    if (offersToShow === 'annual' && !pageData?.annual?.length && pageData?.monthly?.length) {
      setOffersToShow('monthly');
    } else if (
      offersToShow !== 'monthly' &&
      !pageData?.monthly?.length &&
      pageData?.annual?.length
    ) {
      setOffersToShow('annual');
    }
  }, [offersToShow, pageData?.annual?.length, pageData?.monthly?.length]);

  const { showAssistanceNotification, setShowAssistanceNotification } = useAssistanceNotification();

  if (!pageData) return null;

  const {
    steps,
    monthly,
    annual,
    toggleKicker,
    couponId,
    personalCouponId,
    specialSaleData,
    designVersion,
  } = pageData;

  const hasCoupon = couponId || personalCouponId;
  const versionTwo = designVersion === ppDesignVersions.Version2;

  const { currentStep, currentStepIndex } = getCurrentStep(currentStepName, steps);

  const offers = offersToShow === 'monthly' ? monthly : annual;

  const showToggle = !!(monthly?.length && annual?.length);
  const hasOneOffer = offers?.length !== 2;

  const biData = {
    stage: 'offers',
    additional_info: {
      stage_number: (currentStepIndex + 1).toString(),
    },
  };

  const kicker =
    toggleKicker?.billing === 'monthly'
      ? ({
          monthly: [toggleKicketTitleMonthly, toggleKicker?.text],
          yearly: [toggleKicketTitleAnnual],
        } as const)
      : ({
          monthly: [toggleKicketTitleMonthly],
          yearly: [toggleKicketTitleAnnual, toggleKicker?.text],
        } as const);

  const OfferCard = versionTwo ? OfferCardVersionTwo : PPOfferCard;

  return (
    <>
      {specialSaleData ? (
        <SpecialOffers {...specialSaleData} showToggle={showToggle} />
      ) : (
        <PPHeader currentStep={currentStepName} {...currentStep} />
      )}
      {showToggle ? (
        <div className={s9(c.toggle)}>
          <PPOfferToggle
            {...kicker}
            biData={biData}
            designVersion={designVersion}
            onChange={() => {
              setShouldAnimate(true);

              setTimeout(() => {
                setOffersToShow(state => (state === 'annual' ? 'monthly' : 'annual'));
              }, offersAnimationTime * 500);
            }}
          />
        </div>
      ) : null}
      <div
        className={s9(
          c.offers,
          versionTwo && c.newOffers,
          shouldAnimate && c.offersAnimation,
          hasOneOffer && c.oneOffer,
          !showToggle && c.offersWithoutToggle
        )}
        onAnimationEnd={() => {
          setShouldAnimate(false);
        }}
        data-testid="offer-cards-wrapper"
      >
        {offers?.length
          ? offers.map((offer, i) => (
              <OfferCard
                key={offer.contentId}
                styleExtend={[i === 0 && offers?.length !== 1 && c.firstOffer]}
                contracted={i === 0}
                {...offer}
                designVersion={designVersion}
                offerData={offer}
                steps={steps}
                couponId={pageData.couponId}
                personalCouponId={pageData.personalCouponId}
                biData={{
                  ...biData,
                  ...(currentStep.messageIds ? { campaign_name: currentStep.messageIds } : {}),
                }}
              />
            ))
          : null}
      </div>
      <PPCoupon
        defaultComponentToShow={hasCoupon ? 'success' : 'textfield'}
        biData={biData}
        styleExtend={[c.coupon]}
      />
      <OfferLinks specialSaleData={!!specialSaleData} biData={biData} />
      {specialSaleData ? null : <PPImage styleExtend={[c.image]} />}

      {fork({
        default: null,
        htz: (
          <>
            <Notification
              variant="sales"
              priority="low"
              placement="top"
              onClose={() => setShowAssistanceNotification(false)}
              isOpen={showAssistanceNotification}
              ref={notificationRef}
            >
              <span className={s9(c.notifText)} data-testid="user-assist-notif">
                {notificationText}
              </span>
              <Button
                priority="tertiary"
                styleExtend={[c.notifBtn]}
                size="small"
                onClick={() => {
                  setIsModalOpen(true);
                  setShowAssistanceNotification(false);
                  biAction({
                    action_id: 184,
                    feature: 'Promotions help',
                    feature_type: 'Marketing',
                    campaign_name: 'help tool clicked',
                    campaign_details: notificationText,
                  });
                }}
                data-testid="notification-approve-btn"
              >
                כן אשמח
              </Button>
            </Notification>
            <AssitanceModal
              isOpen={isModalOpen}
              onToggle={setIsModalOpen}
              ref={assistanceModalRef}
            />
          </>
        ),
      })}
    </>
  );
}
