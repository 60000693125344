import useUser from '@haaretz/s-atoms/user';
import getFreeProductLink from '@haaretz/s-pp-build-promotions-link/getFreeProductLink';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import { useRouter } from 'next/router';
import React from 'react';

import useFreeProductOrCoupon from '../useFreeProductOrCoupon';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { GetCouponLinkProps } from '@haaretz/s-pp-build-promotions-link/getCouponLink';

type RedeemRegularCouponProps = Omit<GetCouponLinkProps, 'token' | 'personalCouponId'> & {
  nextStepName: PurchasePageSteps;
};

export default function useFreeProduct() {
  const router = useRouter();
  const redeemFree = useFreeProductOrCoupon();
  const user = useUser('cookieValue');

  const redeemRegularCoupon = React.useCallback(
    async ({ nextStepName, ...props }: RedeemRegularCouponProps) => {
      if (!user?.isLoggedIn) {
        const { pathName, asPath } = generatePath({
          nextStepName: 'login',
          asPath: router.asPath,
          currentQueryParams: router.query,
        });

        router.push(pathName, asPath, { shallow: true });
      } else {
        const couponUrl = getFreeProductLink({ ...props });

        return redeemFree(couponUrl, nextStepName);
      }

      return Promise.resolve();
    },
    [redeemFree, router, user?.isLoggedIn]
  );

  return redeemRegularCoupon;
}
