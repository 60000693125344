'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import PpTerms from '@haaretz/s-pp-terms';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const c = s9.create({
  base: {
    ...typesetter(-1),
    ...merge(mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } })),
    ...merge(mq({ from: 'xxl', value: { ...typesetter(-3) } })),
    marginBottom: space(4),
  },
  cancelText: {
    color: color('quaternary700'),
    fontWeight: 700,
  },
  conditionsBtn: {
    color: color('neutral900'),
    backgroundColor: 'transparent',
    position: 'relative',
    textDecoration: 'underline',
    zIndex: zIndex('above'),
    ':hover': {
      color: color('neutral1200'),
    },
  },
});

export type CancellableLineProps = {
  legalText: PurchaseOfferFragment['legalText'];
  biData?: BiDataOverrides;
} & (
  | {
      onTermsButtonClick: (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        biDataExtend?: BiDataOverrides | undefined
      ) => void;
      loading: boolean;
    }
  | {
      onTermsButtonClick?: never;
      loading?: never;
    }
);

export default function CancellableLine({
  legalText,
  biData,
  onTermsButtonClick,
  loading,
}: CancellableLineProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const biAction = useBi();

  const cancelText = 'ניתן לבטל בכל עת.';
  const conditionsBtn = 'תנאי רכישה';

  const isRichTextArray = legalText.every(isHtmlNode);

  const ppTemsOfferProps = onTermsButtonClick
    ? ({
        onButtonClick: onTermsButtonClick,
        loading,
      } as const)
    : ({} as const);

  return (
    <div className={s9(c.base)} data-testid="offer-cancellable-line">
      <span className={s9(c.cancelText)}>
        {cancelText}{' '}
        <button
          data-testid="offer-cancellable-line-btn"
          className={s9(c.conditionsBtn)}
          onClick={() => {
            setIsOpen(!isOpen);
            biAction({
              ...biData,
              action_id: 26,
            });
          }}
        >
          {conditionsBtn}
        </button>
      </span>
      <PpTerms
        onToggle={setIsOpen}
        isOpen={isOpen}
        title={fork({
          default: 'תנאי רכישה',
          hdc: 'Terms and Conditions',
        })}
        labelledBy="purchase-terms"
        content={isRichTextArray ? legalText : []}
        {...ppTemsOfferProps}
      />
    </div>
  );
}
