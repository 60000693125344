'use client';

import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import TextLink from '@haaretz/s-text-link';
import useBi from '@haaretz/s-use-bi';
import { useRouter } from 'next/router';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  base: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(2),

    ...merge(
      { ...typesetter(0) },
      mq({ until: 'xxl', value: { marginBottom: space(6) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } })
    ),
  },
  upperLine: {
    display: 'flex',
    columnGap: space(2),

    ...mq({ until: 'xl', value: { flexDirection: 'column', alignItems: 'center' } }),
  },
  secondLine: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: space(1),

    ...mq({ until: 'xl', value: { marginTop: space(3) } }),
  },

  withSpecialSaleData: {
    marginBottom: space(8),
  },
});

interface OfferLinksProps {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  specialSaleData?: boolean;
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
}

const STUDENTS_AND_SOLDIERS_PATH_SEGMENT = fork({
  default: 'students-both',
  tm: 'students-tm',
});

export default function OfferLinks({
  inlineStyle,
  specialSaleData,
  styleExtend = [],
  biData,
}: OfferLinksProps) {
  const router = useRouter();
  const biAction = useBi();

  const isStudentAndSoldierOffers = router.query.pathSegment === STUDENTS_AND_SOLDIERS_PATH_SEGMENT;

  return (
    <div
      style={inlineStyle}
      className={s9(c.base, specialSaleData && c.withSpecialSaleData, ...styleExtend)}
    >
      <div className={s9(c.upperLine)}>
        <span>כבר מנויים למהדורה המודפסת?</span>
        <TextLink
          href={fork({
            default: 'https://login.haaretz.co.il/misc/entitlement',
            tm: 'https://login.themarker.com/misc/entitlement',
          })}
          onClick={() => {
            biData &&
              biAction?.({
                ...biData,
                action_id: 40,
                feature: 'Promotions footer',
                feature_type: 'Marketing',
              });
          }}
          data-testid="offer-entitlement-link"
        >
          בדקו אם אתם זכאים להנחה
        </TextLink>
      </div>
      <div className={s9(c.secondLine)}>
        <TextLink
          href={fork({
            default: 'https://www.lndit.co/6016a881af8b9.htm',
            tm: 'https://www.lndit.co/6016998b5ea77.htm',
          })}
          onClick={() => {
            biAction({
              ...biData,
              action_id: 50,
              feature: 'Promotions footer',
              feature_type: 'Marketing',
            });
          }}
          data-testid="offer-organization-link"
        >
          לרכישת מינוי ארגוני
        </TextLink>
        {isStudentAndSoldierOffers ? (
          <TextLink data-testid="back-to-subscription-options-link" href="/offers">
            חזרה למחיר הרגיל
          </TextLink>
        ) : (
          <TextLink
            href={`/${STUDENTS_AND_SOLDIERS_PATH_SEGMENT}/offers`}
            data-testid="offer-students-and-soldiers-link"
          >
            מחיר לסטודנטים ולחיילים
          </TextLink>
        )}
      </div>
    </div>
  );
}
