import getPurchaseServiceLink from '../getPurchaseServiceLink';

import type { CommonPaymentParameters } from '../getCommonParameters';

export type GetPaymnetChangeLinkProps = Required<
  Pick<CommonPaymentParameters, 'productNumber' | 'promotionNumber' | 'saleCode'>
> & {
  msPurchaseUrl: string;
};

export default function getIFrameLink({
  msPurchaseUrl,
  productNumber,
  promotionNumber,
  saleCode,
}: GetPaymnetChangeLinkProps) {
  const searchParams = new URLSearchParams({
    saleCode: `${saleCode}`,
    productId: `${productNumber}`,
    promotionNo: `${promotionNumber}`,
  });

  return getPurchaseServiceLink({ msPurchaseUrl, searchParams, endpoint: 'changePlan' });
}
