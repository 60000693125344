'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { RadioGroupContextProvider } from '@haaretz/s-use-radio-group-state';
import * as React from 'react';
import s9 from 'style9';

import type { PolymorphicPropsWithoutRef, StyleExtend, InlineStyles } from '@haaretz/s-types';
import type { RadioGroupContextProps } from '@haaretz/s-use-radio-group-state';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    '--lbl-c': color('neutral900'),
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: space(4),
    rowGap: space(3),
  },
  verticalOrientation: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  horizontalOrientation: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  errorState: {
    '--lbl-c': color('secondary700'),
  },
  label: {
    color: 'var(--lbl-c)',
    fontWeight: 700,
    ...merge(
      { ...typesetter(-2) },
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  labelRequired: {
    ':after': {
      content: '" *"',
      color: color('secondary900'),
    },
  },
});

export const DEFAULT_ELEMENT = 'fieldset';
type DefaultElement = typeof DEFAULT_ELEMENT;

export interface RadioGroupOwnProps<T extends string> {
  /** The Children to be rendered inside `<RadioGroup>` */
  children?: React.ReactNode;
  /** Marks the group as invalid being in an invalid state. */
  isInvalid?: boolean;
  /** The functional state of the group */
  state?: RadioGroupContextProps<T>['state'];
  /** Marks the `<RadioGroup>` as requiring a selection */
  isRequired?: boolean;
  /**
   * Text to visually label the `<RadioGroup>` with.
   */
  labelText?: string;
  /**
   * Remove all styling from the component and turns it
   * into one that purely manages state and accessibility.
   *
   * Use in conjucntion with `styleExtend`, `inlineStyle`,
   * `labelStyleExtend` and `labelInlineStyle` to add your
   * own styles to the wrapper and label elements rendered
   * by the component.
   */
  isUnstyled?: boolean;
  /** The shared `name` attribute to assign to all radio buttons in the group */
  groupName?: string;
  /**
   * Sets the orientation of the radio-buttons in the group,
   * both visually (as long as `isUnstyled` is not set) and to
   * screen readers.
   *
   * @defaultValue 'vertical'
   */
  orientation?: 'vertical' | 'horizontal';
  /**
   * A callback fired whenever a radio button is checked.
   * Passed the value of the checked input and the html element.
   */
  onChange?: (value: T, checkedElement: HTMLInputElement) => void;
  /**
   * The value of the radio button that is checked by default.
   */
  defaultValue?: string;
  /**
   * CSS declarations to be set as inline `style` on the
   * wrapper html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyRadioButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <RadioButton
   *      labelStyleExtend={[ labelStyleExtend, ]}
   *      labelInlineStyle={labelInlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()` which will
   * extend the default styles assigned to the wrapper element.
   *
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   *
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <RadioButton styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * CSS declarations to be set as inline `style` on the
   * label's html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { labelStyleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyRadioButton(props) {
   *   const labelInlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <RadioButton
   *      labelStyleExtend={[ labelStyleExtend, ]}
   *      labelInlineStyle={labelInlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  labelInlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()` which will
   * extend the default styles assigned to the label element.
   *
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   *
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <RadioButton labelStyleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  labelStyleExtend?: StyleExtend;
}

type AllowedElements = 'div' | 'fieldset' | 'section' | 'span' | 'aside';

export type RadioGroupProps<
  T extends string,
  As extends React.ElementType = DefaultElement,
> = PolymorphicPropsWithoutRef<RadioGroupOwnProps<T>, As, AllowedElements>;

function RadioGroup<T extends string, As extends React.ElementType = AllowedElements>({
  as,
  children,
  groupName,
  state = 'enabled',
  isRequired,
  isInvalid,
  inlineStyle,
  styleExtend = [],
  labelInlineStyle,
  labelStyleExtend = [],
  labelText,
  orientation = 'vertical',
  isUnstyled = false,
  defaultValue,
  onChange,
  ...attrs
}: RadioGroupProps<T, As>) {
  const isStyled = !isUnstyled;

  const defaultGroupName = React.useId();
  const labelId = React.useId();

  const radioGroupRef = React.useRef<HTMLElement | null>(null);
  const [firstRadioButton, setFirstRadioButton] = React.useState<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (radioGroupRef.current) {
      const firstRadioFromDom = radioGroupRef.current.querySelector('input[type="radio"]');
      if (firstRadioFromDom) setFirstRadioButton(firstRadioFromDom as HTMLInputElement);
    }
  }, []);

  const WrapperElement: React.ElementType = as || DEFAULT_ELEMENT;

  const a11yOverride = {
    role: 'radiogroup',
    'aria-disabled': state === 'disabled' || undefined,
    'aria-readonly': state === 'read-only' || undefined,
    'aria-errormessage': isInvalid ? attrs['aria-errormessage'] : undefined,
    'aria-invalid': isInvalid || undefined,
    'aria-orientation': orientation,
    'aria-required': isRequired || undefined,
    'aria-labelledby': attrs['aria-labelledby'] || (labelText ? labelId : undefined),
    'aria-describedby': isInvalid ? undefined : attrs['aria-describedby'],
  };

  return (
    <WrapperElement
      {...attrs}
      {...a11yOverride}
      style={inlineStyle}
      className={s9(
        isStyled && c.base,
        isStyled && (orientation === 'vertical' ? c.verticalOrientation : c.horizontalOrientation),
        isInvalid && c.errorState,
        ...styleExtend
      )}
      ref={radioGroupRef}
    >
      {labelText ? (
        <span
          id={labelId}
          className={s9(isStyled && c.label, isRequired && c.labelRequired, ...labelStyleExtend)}
          style={labelInlineStyle}
        >
          {labelText}
        </span>
      ) : null}
      <RadioGroupContextProvider
        defaultValue={defaultValue}
        isRequired={isRequired}
        firstRadioButton={firstRadioButton}
        onChange={onChange}
        name={groupName ?? defaultGroupName}
        state={state}
      >
        {children}
      </RadioGroupContextProvider>
    </WrapperElement>
  );
}

export default RadioGroup;
