'use client';

import * as React from 'react';

///////////////
//  Context  //
///////////////

export interface RadioGroupContextProps<T extends string> {
  defaultValue?: string;
  firstRadioButton: HTMLInputElement | null;
  groupChecked: [string, HTMLInputElement] | null;
  hasCheckedInGroup: boolean;
  isRequired?: boolean;
  name: string;
  onChange?: (value: T, element: HTMLInputElement) => void;
  setGroupChecked: (checked: [string, HTMLInputElement]) => void;
  state?: 'enabled' | 'disabled' | 'read-only';
}

export type EmptyRadioGroupContextProps<T extends string> = Partial<
  Record<keyof RadioGroupContextProps<T>, undefined>
>;

function contextFactory() {
  const Context = React.createContext<
    RadioGroupContextProps<string> | EmptyRadioGroupContextProps<string>
  >({});
  return function <T extends string>() {
    type TypedContext =
      | RadioGroupContextProps<T>
      | Partial<Record<keyof RadioGroupContextProps<T>, undefined>>;

    return Context as React.Context<TypedContext>;
  };
}

const getRadioGroupContext = contextFactory();

////////////////
//  Provider  //
////////////////

/* eslint-disable @typescript-eslint/indent */
type RadioGroupContextProviderProps<T extends string> = Pick<
  RadioGroupContextProps<T>,
  'defaultValue' | 'firstRadioButton' | 'name' | 'onChange' | 'isRequired' | 'state'
  /* eslint-enable @typescript-eslint/indent */
>;

type EmptyRadioGroupContextProviderProps<T extends string> = Partial<
  Record<keyof RadioGroupContextProviderProps<T>, undefined>
>;

type ProviderPropsSansChildern<T extends string> =
  | RadioGroupContextProviderProps<T>
  | EmptyRadioGroupContextProviderProps<T>;

export type ProviderProps<T extends string> = ProviderPropsSansChildern<T> & {
  children?: React.ReactNode;
};

export function RadioGroupContextProvider<T extends string>({
  children,
  defaultValue,
  firstRadioButton,
  isRequired,
  name,
  onChange,
  state,
}: ProviderProps<T> = {}) {
  const RadioGroupContext = getRadioGroupContext<T>();

  const [
    // This is an array of the value of the checked radio button and the element itself.
    groupChecked,
    setGroupChecked,
  ] = React.useState<[string, HTMLInputElement] | null>(null);

  if (name != null) {
    const hasCheckedInGroup = !!groupChecked;

    return (
      <RadioGroupContext.Provider
        value={{
          defaultValue,
          firstRadioButton,
          groupChecked,
          hasCheckedInGroup,
          isRequired,
          name,
          onChange,
          setGroupChecked,
          state,
        }}
      >
        {children}
      </RadioGroupContext.Provider>
    );
  }

  return <RadioGroupContext.Provider value={{}}>{children}</RadioGroupContext.Provider>;
}

////////////
//  Hook  //
////////////

/* eslint-disable @typescript-eslint/indent */
export type UseRadioGroupStateProps = Pick<
  React.ComponentPropsWithoutRef<'input'>,
  'defaultChecked' | 'onChange'
  /* eslint-enable @typescript-eslint/indent */
> & {
  /**
   * The `checked` state of the radioButton or toggle.
   * @defaultValue undefined
   */
  checked?: React.ComponentPropsWithoutRef<'input'>['checked'];
  input: HTMLInputElement | null;
  isRequired?: boolean;
};
export default function useRadioGroupState<T extends string>({
  checked,
  defaultChecked,
  input,
  isRequired: isRequiredProp,
  onChange: onChangeFromProp,
}: UseRadioGroupStateProps) {
  const RadioGroupContext = getRadioGroupContext<T>();

  const {
    defaultValue,
    firstRadioButton,
    groupChecked,
    hasCheckedInGroup,
    isRequired: isRequiredFromGroup,
    name,
    onChange: onGroupChange,
    setGroupChecked,
    state,
  } = React.useContext(RadioGroupContext);

  const value = input?.value ?? undefined;

  const groupCheckedValue = groupChecked && groupChecked[0];
  const isCheckedByGroup = !!groupChecked && !!value && groupCheckedValue === value;
  const isDefaultCheckedByProp =
    (defaultValue == null && defaultChecked) || (value && defaultValue === value);
  const isDefaultCheckedByGroup =
    groupCheckedValue != null && !!value && groupCheckedValue === value;
  const isDefaultChecked =
    !hasCheckedInGroup && (isDefaultCheckedByGroup || isDefaultCheckedByProp);

  const isControlled = checked !== undefined;

  const isChecked = isControlled ? checked : isDefaultChecked || isCheckedByGroup;

  const isFirstInGroup = !!(input && input === firstRadioButton);

  const tabIndex = isChecked ? 0 : hasCheckedInGroup ? -1 : isFirstInGroup ? 0 : -1;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    evt => {
      if (setGroupChecked) {
        setGroupChecked([evt.currentTarget.value, evt.currentTarget]);
      }
      if (typeof onChangeFromProp === 'function') onChangeFromProp(evt);
      if (onGroupChange) onGroupChange(evt.currentTarget.value as T, evt.currentTarget);
    },
    [onChangeFromProp, onGroupChange, setGroupChecked]
  );

  return {
    checkedElement: groupChecked && groupChecked[1],
    firstRadioButton,
    isChecked,
    isRequired: isRequiredProp ?? isRequiredFromGroup,
    name,
    onChange,
    tabIndex,
    state,
  };
}
