import useUser from '@haaretz/s-atoms/user';
import getCouponLink from '@haaretz/s-pp-build-promotions-link/getCouponLink';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import useConfig from '@haaretz/s-use-config';
import { useRouter } from 'next/router';
import React from 'react';

import useFreeCoupon from '../useFreeProductOrCoupon';

import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { GetCouponLinkProps } from '@haaretz/s-pp-build-promotions-link/getCouponLink';

type RedeemRegularCouponProps = Omit<GetCouponLinkProps, 'token' | 'personalCouponId'> & {
  couponId: NonNullable<PageDataFragment['couponId']>;
  token?: never;
  personalCouponId?: never;
  nextStepName: PurchasePageSteps;
};

export default function useRegularFreeCoupon() {
  const router = useRouter();
  const redeemFreeCoupon = useFreeCoupon();
  const user = useUser('cookieValue');
  const config = useConfig();

  const redeemRegularCoupon = React.useCallback(
    async ({ nextStepName, ...props }: RedeemRegularCouponProps) => {
      if (!user?.isLoggedIn) {
        const { pathName, asPath } = generatePath({
          nextStepName: 'login',
          asPath: router.asPath,
          currentQueryParams: router.query,
        });

        router.push(pathName, asPath, { shallow: true });
      } else {
        const confirmationUrl = config.get('msConfirmation');

        const headers = new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        });

        try {
          const token = await fetch(`${confirmationUrl}/generateConfirmation`, {
            method: 'POST',
            body: JSON.stringify({
              confirmationParams: {
                email: user.userMail,
              },
            }),
            headers,
          }).then(res => res.text());

          if (!token) {
            throw new Error("Token isn't valid");
          }

          const couponUrl = getCouponLink({ ...props, token: decodeURIComponent(token) });

          return redeemFreeCoupon(couponUrl, nextStepName);
        } catch (error) {
          const { pathName, asPath } = generatePath({
            nextStepName: 'error',
            asPath: router.asPath,
            currentQueryParams: router.query,
          });

          router.push(pathName, asPath, { shallow: true });
        }
      }

      return Promise.resolve();
    },
    [config, redeemFreeCoupon, router, user?.isLoggedIn, user?.userMail]
  );

  return redeemRegularCoupon;
}
