import getChangePlanLink from '../getChangePlanLink';

import type { GetPaymnetChangeLinkProps } from '../getChangePlanLink';

export default function fetchChangePlan(props: GetPaymnetChangeLinkProps) {
  const changePlanLink = getChangePlanLink(props);

  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  return fetch(changePlanLink, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
}
