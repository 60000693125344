import type { PageDataFragment } from '@haaretz/s-fragments/PageData';

export default function isCoupon(
  pageData: Pick<PageDataFragment, 'couponId' | 'personalCouponId'>
): pageData is
  | {
      couponId: NonNullable<PageDataFragment['couponId']>;
    }
  | {
      personalCouponId: NonNullable<PageDataFragment['personalCouponId']>;
    } {
  if (!(pageData instanceof Object)) return false;

  return !!(pageData.personalCouponId || pageData.couponId);
}
