'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import { ppDesignVersions } from '@haaretz/s-consts';
import Icon from '@haaretz/s-icon';
import RichText from '@haaretz/s-rich-text';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const iconSize = space(4);

const c = s9.create({
  base: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: space(2),
  },
  baseVersionTwo: {
    marginTop: 0,
  },
  line: {
    marginBottom: space(2),
    display: 'flex',
  },
  lineVersionTwo: {
    marginBottom: space(1),

    ...merge(
      mq({ until: 's', value: { ...typesetter(-1) } }),
      mq({ from: 's', until: 'm', value: { ...typesetter(-1) } }),
      mq({ from: 'm', until: 'l', value: { ...typesetter(-1) } }),
      mq({ from: 'l', until: 'xl', value: { ...typesetter(-1) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  heading: {
    ...typesetter(0),
    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-1) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } })
    ),
    marginBottom: space(2),
  },

  richText: {
    ...merge(
      mq({ until: 's', value: { ...typesetter(-1) } }),
      mq({ from: 's', until: 'm', value: { ...typesetter(-1) } }),
      mq({ from: 'm', until: 'l', value: { ...typesetter(-1) } }),
      mq({ from: 'l', until: 'xl', value: { ...typesetter(-1) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  checkmark: {
    fontSize: iconSize,
    marginInlineEnd: space(1),
    marginBottom: space(0.5),
    minWidth: iconSize,
  },
  button: {
    fontWeight: 700,
    backgroundColor: 'transparent',
    fontSize: iconSize,
    position: 'relative',
    zIndex: zIndex('above'),
  },
  buttonVersionTwo: {
    ...typesetter(-2),
    marginBottom: space(1),

    ...merge(mq({ from: 'xxl', value: { ...typesetter(-3) } })),
  },
  hiddenDesktop: { ...merge(mq({ from: 's', value: { display: 'none' } })) },
  hiddenMobile: { ...merge(mq({ until: 's', value: { display: 'none' } })) },
  hide: {
    display: 'none',
  },
  rotateIcon: {
    transform: 'rotate(90deg)',
  },
});

export interface OfferBenefitsProps {
  /**
   * The items to be shown
   */
  items: PurchaseOfferFragment['benefits'];
  /**
   * The heading of the items
   */
  heading?: PurchaseOfferFragment['benefitsHeading'];
  /**
   * Adds an arrow button that acts as a collapse for the items
   */
  contracted?: boolean;
  biData?: BiDataOverrides;
  designVersion?: PageDataFragment['designVersion'];
}

export default function OfferBenefits({
  items,
  heading,
  contracted,
  biData,
  designVersion,
}: OfferBenefitsProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const biAction = useBi();
  const versionTwo = designVersion === ppDesignVersions.Version2;

  return (
    <div className={s9(c.base, versionTwo && c.baseVersionTwo)} data-testid="offer-benefits">
      {heading && <h3 className={s9(c.heading)}>{heading}</h3>}
      {items?.map((item, index) => {
        if (!isHtmlNode(item)) return null;

        const { as, content } = item;

        const shouldShow = (versionTwo || contracted) && index !== 0 ? isOpen : true;
        const hideClass = versionTwo ? c.hide : c.hiddenMobile;

        return (
          <div
            key={content}
            className={s9(c.line, !shouldShow && hideClass, versionTwo && c.lineVersionTwo)}
          >
            <Icon
              icon="check"
              variant={versionTwo ? 'brand' : 'success'}
              styleExtend={[c.checkmark]}
            />
            <RichText as={as} content={content} styleExtend={[c.richText]} />
          </div>
        );
      })}
      {(versionTwo || contracted) && !isOpen && (
        <button
          className={s9(c.button, !versionTwo && c.hiddenDesktop, versionTwo && c.buttonVersionTwo)}
          onClick={() => {
            setIsOpen(prev => !prev);
            biData &&
              biAction({
                ...biData,
                action_id: 169,
                feature: 'Promotions benefits mobile arrow',
                feature_type: 'Marketing',
              });
          }}
        >
          עוד יתרונות <Icon icon="chevron" styleExtend={[c.rotateIcon]} />
        </button>
      )}
    </div>
  );
}
