import { currency } from '@haaretz/s-consts';
import generateSubscriptionText from '@haaretz/s-generate-subscription-text';
import { gtagAddToCartEvent } from '@haaretz/s-gtag-utils/gtagEvent';

import type { RequiredSelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';

type GtagAddToCartProps = {
  offerData: RequiredSelectedOfferProps;
  couponId?: PageDataFragment['couponId'];
};

export default function gtagAddToCart({ offerData, couponId }: GtagAddToCartProps) {
  gtagAddToCartEvent({
    currency,
    value: offerData.prices[1],
    items: [
      {
        id: `${offerData.saleCode}`,
        name: generateSubscriptionText(offerData.periodCalc, offerData.productNumber),
        currency,
        quantity: 1,
        price: offerData.prices[0],
        coupon: couponId ? /* istanbul ignore next */ couponId : undefined,
      },
    ],
  });
}
