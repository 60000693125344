'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
import FormfieldDescription from '@haaretz/s-formfield-description';
import Icon from '@haaretz/s-icon';
import useIntersectionObserver from '@haaretz/s-use-intersection-observer/common';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

const inlineSpacing = 2;
const borderWidth = '1px';
const maxAllowedOffsetFromViewPort = 8;
const dropdownSpacing = 1.5;

// `c` is short for `classNames`
const c = s9.create({
  base: {
    '--lbl-drpdwn-brdr-c': color('neutral100'),
    color: 'var(--drpdwn-itm-c)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ...typesetter(0),

    ...mq({ from: 'xxl', value: { ...typesetter(-1) } }),
  },

  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: space(2),
    fontWeight: 700,
    backgroundColor: 'transparent',
    borderTopLeftRadius: radius('small'),
    borderTopRightRadius: radius('small'),
    borderBottomLeftRadius: radius('small'),
    borderBottomRightRadius: radius('small'),
    transitionProperty: 'border-color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-in-out',

    ...border({
      color: 'var(--drpdwn-brdr-c)',
      spacing: dropdownSpacing,
      style: 'solid',
      width: borderWidth,
      side: 'block',
    }),

    ...border({
      color: 'var(--drpdwn-brdr-c)',
      spacing: inlineSpacing,
      style: 'solid',
      width: borderWidth,
      side: 'inline',
    }),

    ':focus-within': {
      outline: 'none',
      '--drpdwn-brdr-c': 'var(--drpdwn-hvr-brdr-c)',
    },

    ':hover': {
      '--drpdwn-brdr-c': 'var(--drpdwn-hvr-brdr-c)',
    },
  },
  labelWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    insetInlineStart: space(2),
    zIndex: 1,
    paddingRight: space(1),
    paddingLeft: space(1),
    paddingBottom: space(0.75),

    ...border({
      width: '2px',
      color: 'var(--lbl-drpdwn-brdr-c)',
      style: 'solid',
      spacing: 1,
      side: 'top',
    }),

    display: 'flex',
    alignItems: 'center',
  },
  label: {
    display: 'inline-block',
    position: 'relative',
    backgroundColor: 'transparent',
    transform: 'translateY(55%)',
    top: '-100%',
    insetInlineStart: 0,
    fontWeight: 700,
    color: color('neutral900'),
    ...typesetter(-2),

    ...mq({
      from: 'xxl',
      value: {
        ...typesetter(-3),
      },
    }),
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.25,
  },
  disabledOption: {
    color: 'var(--disbld-slctd-itm)',
  },
  icon: {
    transform: 'rotate(90deg)',
    ...typesetter(0),
  },
  optionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    insetInlineStart: '-1px',
    width: 'calc(100% + 2px)',
    paddingRight: space(0.25),
    paddingLeft: space(0.25),
    overflowY: 'auto',
    backgroundColor: color('neutral100'),
    zIndex: 2,

    ':focus-visible': {
      outlineWidth: 0,
    },

    ...mq({ until: 's', value: { scrollbarWidth: 'none' } }),
  },
  dropdownOption: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: color('neutral100'),
    columnGap: space(1),

    ...border({
      color: 'var(--drpdwn-brdr-c)',
      spacing: inlineSpacing,
      style: 'solid',
      width: borderWidth,
      side: 'inline',
    }),

    paddingTop: space(dropdownSpacing),
    paddingBottom: space(dropdownSpacing),
  },
  dropdownOptionBorderBottom: {
    ...border({
      color: 'var(--drpdwn-brdr-c)',
      spacing: 0,
      style: 'solid',
      width: borderWidth,
      side: 'bottom',
    }),
  },
  dropdownOptionBorderTop: {
    ...border({
      color: 'var(--drpdwn-brdr-c)',
      spacing: 0,
      style: 'solid',
      width: borderWidth,
      side: 'top',
    }),
  },
  dropdownOptionBorderRadiusTop: {
    borderTopLeftRadius: radius('small'),
    borderTopRightRadius: radius('small'),
  },
  dropdownOptionBorderRadiusBottom: {
    borderBottomLeftRadius: radius('small'),
    borderBottomRightRadius: radius('small'),
  },
  selected: {
    backgroundColor: 'var(--slctd-itm)',
  },
  brandVariant: {
    '--drpdwn-itm-c': color('primary1000'),
    '--drpdwn-brdr-c': color('primary1000', { opacity: 0.2 }),
    '--slctd-itm': color('primary200'),
    '--disbld-slctd-itm': color('primary1000', { opacity: 0.25 }),
    '--drpdwn-hvr-brdr-c': color('primary1000'),
  },
  neutralVariant: {
    '--drpdwn-itm-c': color('neutral1200'),
    '--drpdwn-brdr-c': color('neutral1200', { opacity: 0.2 }),
    '--slctd-itm': color('neutral200'),
    '--disbld-slctd-itm': color('neutral1200', { opacity: 0.25 }),
  },
  isInvalid: {
    '--drpdwn-brdr-c': color('secondary900', { opacity: 0.2 }),
    '--drpdwn-hvr-brdr-c': color('secondary900'),
  },
  paddingDropdownOption: {
    paddingInlineStart: `calc(${space(inlineSpacing)} + ${space(5)})`,
  },
  zIndexDropdown: {
    zIndex: zIndex('dropdown'),
  },
  required: {
    color: color('secondary900'),
    marginInlineStart: space(1),
  },
});

export type Option = {
  /**
   * The displayed text of the option.
   */
  text: string;
  /**
   * The value accosiated with the option.
   */
  value?: string;
  /**
   * Disables the option for user selection
   */
  disabled?: boolean;
};

interface DropdownOwnProps {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyDropdown(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Dropdown
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Dropdown styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * An array of `Style9` styles to be applied on the select */
  selectStyleExtend?: StyleExtend;
  /**
   * An array of `Style9` styles to be applied on the wrapper */
  optionsWrapperStyleExtend?: StyleExtend;
  /**
   * The options available in dropdown
   */
  options: Option[];
  /**
   * Sets the color of the dropdown
   *
   * @defaultValue 'brand'
   */
  variant?: 'brand' | 'neutral';
  /**
   * Labels the field to assist with user comprehension
   */
  label: string;
  /**
   * A callback allowing a consuming component to respond to internal state
   * changes. The callback is passed the index of the selected option.
   */
  onChange?: (selectedOption: number | undefined) => void;
  /**
   * The index of the initially selected option
   */
  initialSelectedOption?: number;
  /**
   * If you want to control the component you must provide state and setState.
   *
   * You have the option to disable keyboard control ('UpArrow', 'DownArrow' and 'Space')
   * with keyboardControl 'contorlled', default is 'uncontorlled'.
   *
   * You have the option to disable keyboard prevent scrolling which prevents scrolling
   * with 'UpArrow' and 'DownArrow' with preventKeyboardScrolling 'controlled',
   * default is 'uncontrolled'.
   */
  controlledComponent?: {
    state: number | undefined;
    setState: React.Dispatch<React.SetStateAction<number | undefined>>;
    keyboardControl?: 'controlled' | 'uncontrolled';
    preventKeyboardScrolling?: 'controlled' | 'unconrtolled';
  };
  /** Disable the dropdown */
  disabled?: boolean;
  /** Indicates the dropdown is in an invalid state */
  isInvalid?: boolean;
  /** Textual info below the dropdown */
  description?: string;
  /** Indicates if the dropdown is required */
  required?: boolean;
}

type GetDropdownPositionArgs = {
  btnElement: HTMLButtonElement;
  selectedOption: number;
};

export interface DropdownProps
  extends DropdownOwnProps,
    Omit<React.ComponentPropsWithRef<'div'>, 'onChange' | 'ref'> {}
interface DropdownOptionProps extends Pick<DropdownOwnProps, 'onChange'> {
  text: string;
  setActualSelectedOption: React.Dispatch<React.SetStateAction<number | undefined>>;
  setVisuallySelectedOption: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentOption: number;
  selectedOption?: number;
  actualSelectedOption?: number;
  isLastOption: boolean;
  variant: DropdownProps['variant'];
  disabled?: boolean;
}

const DropdownOption = ({
  text,
  setActualSelectedOption,
  setVisuallySelectedOption,
  setIsOpen,
  currentOption,
  selectedOption,
  isLastOption,
  actualSelectedOption,
  variant,
  disabled,
  onChange,
}: DropdownOptionProps) => {
  const onClick = () => {
    setIsOpen(false);

    if (actualSelectedOption !== currentOption) {
      setVisuallySelectedOption(currentOption);
      setActualSelectedOption(currentOption);
      onChange?.(currentOption);
    }
  };

  const isFirstOption = currentOption === 0;

  const ariaSelected = selectedOption === currentOption ? 'true' : undefined;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="option"
      className={s9(
        c.dropdownOption,
        selectedOption === currentOption && c.selected,
        disabled && c.disabledOption,
        c.dropdownOptionBorderTop,
        isLastOption && c.dropdownOptionBorderBottom,
        isFirstOption && c.dropdownOptionBorderRadiusTop,
        isLastOption && c.dropdownOptionBorderRadiusBottom,
        actualSelectedOption !== currentOption && c.paddingDropdownOption
      )}
      onClick={disabled ? undefined : onClick}
      onMouseMove={() => setVisuallySelectedOption(currentOption)}
      aria-selected={ariaSelected}
      tabIndex={-1}
      id={`dropdown-option-${currentOption}`}
      aria-disabled={disabled}
    >
      {actualSelectedOption === currentOption && <Icon icon="check" variant={variant} />}
      {text}
    </div>
  );
};

const Dropdown = React.forwardRef<HTMLDivElement, DropdownOwnProps>(function Dropdown(
  {
    inlineStyle,
    styleExtend = [],
    optionsWrapperStyleExtend = [],
    options,
    selectStyleExtend = [],
    variant = 'brand',
    label,
    onChange,
    initialSelectedOption,
    controlledComponent,
    disabled,
    description,
    isInvalid,
    required = false,
    ...attrs
  }: DropdownProps,
  ref
) {
  const buttonId = React.useId();
  const labelId = React.useId();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedOptionInternal, setSelectedOptionInternal] = React.useState<number | undefined>(
    initialSelectedOption
  );
  const actualSelectedOption = controlledComponent
    ? controlledComponent.state
    : selectedOptionInternal;
  const [visuallySelectedOption, setVisuallySelectedOption] = React.useState<number | undefined>(
    initialSelectedOption
  );
  const [dropdownPosition, setDropdownPosition] = React.useState<React.CSSProperties>({});
  const setActualSelectedOption = controlledComponent
    ? controlledComponent.setState
    : setSelectedOptionInternal;
  const optionsWrapperId = React.useId();
  const optionsWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const isKeyboardControlled = controlledComponent?.keyboardControl === 'controlled';
  const [shouldStopReposition, setShouldStopReposition] = React.useState(false);

  const variantClass: `${DropdownOwnProps['variant']}Variant` = `${variant}Variant`;
  const dropdownOptionHeight = btnRef.current?.offsetHeight;
  const descriptionId = React.useId();

  // reposition so the selected option overlaps with the dropdown button
  const optionsWrapperRefCb = React.useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        if (btnRef.current && actualSelectedOption != null && !shouldStopReposition) {
          const newDropdownPosition = getDropdownPositionForOverlap({
            btnElement: btnRef.current,
            selectedOption: actualSelectedOption,
          });
          setDropdownPosition(newDropdownPosition);
        }

        optionsWrapperRef.current = node;
      }
    },
    [actualSelectedOption, shouldStopReposition]
  );

  // reposition dropdown if not enough space
  React.useLayoutEffect(() => {
    if (isOpen && optionsWrapperRef.current && !shouldStopReposition) {
      const dropdownReposition = getDropdownReposition(
        optionsWrapperRef.current.getBoundingClientRect(),
        dropdownOptionHeight
      );

      if (dropdownReposition) setDropdownPosition(dropdownReposition);
      if (dropdownReposition?.maxHeight) setShouldStopReposition(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, optionsWrapperRef.current]);

  // match the visually selected option with the actual selected option
  React.useEffect(() => {
    setVisuallySelectedOption(actualSelectedOption);
  }, [actualSelectedOption]);

  React.useEffect(() => {
    if (isOpen && optionsWrapperRef.current) {
      optionsWrapperRef.current.focus();
    }

    const preventKeyboardScrolling = (e: KeyboardEvent) => {
      if ([' ', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
        e.preventDefault();
      }
    };

    if (isOpen) {
      if (controlledComponent?.preventKeyboardScrolling !== 'controlled') {
        window.addEventListener('keydown', preventKeyboardScrolling);
      }
    }

    return () => {
      if (controlledComponent?.preventKeyboardScrolling !== 'controlled') {
        window.removeEventListener('keydown', preventKeyboardScrolling);
      }
    };
  }, [controlledComponent?.preventKeyboardScrolling, isOpen]);

  const scrollIntoViewVisSelectedOffer = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const element = entries[0];
      const isFullyInViewPort = element.isIntersecting;
      if (!isFullyInViewPort) {
        element.target.scrollIntoView({ block: 'nearest', behavior: 'instant' });
      }
    },
    []
  );

  const observer = useIntersectionObserver({
    cb: scrollIntoViewVisSelectedOffer,
    threshold: 1,
  });

  // scroll to selected option on navigation
  React.useEffect(() => {
    const visSelectedOption =
      visuallySelectedOption != null &&
      optionsWrapperRef?.current?.children[visuallySelectedOption];

    if (visSelectedOption && observer) observer.observe(visSelectedOption);

    return () => {
      if (visSelectedOption && observer) observer.unobserve(visSelectedOption);
    };
  }, [observer, visuallySelectedOption]);

  // scroll to already selected option on open
  React.useEffect(() => {
    if (isOpen && optionsWrapperRef.current && actualSelectedOption && dropdownOptionHeight) {
      const yAxisToScrollTo = dropdownOptionHeight * actualSelectedOption;

      optionsWrapperRef.current.scrollTo(0, yAxisToScrollTo);
    }
  }, [isOpen, actualSelectedOption, dropdownOptionHeight]);

  const onClick = () => {
    setIsOpen(prev => !prev);
  };

  const btnOnKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      setIsOpen(true);
    }
  };

  if (controlledComponent) {
    if (!controlledComponent.setState) {
      throw new Error('<Dropdown />: controlled component must have setState');
    }

    if (!Object.keys(controlledComponent).find(key => key === 'state')) {
      throw new Error('<Dropdown />: controlled component must have state');
    }
  }

  const optionsWrapperOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (e.relatedTarget?.getAttribute('role') === 'option' || e.relatedTarget?.id === buttonId) {
      return;
    }

    setVisuallySelectedOption(actualSelectedOption);
    setIsOpen(false);
  };

  const optionsWrapperKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowUp') {
      setVisuallySelectedOption(currentOption => {
        if (currentOption == null) return options.length - 1;
        const prevOption = getPrevValidOption(currentOption, options);

        return prevOption;
      });
    }
    if (e.key === 'ArrowDown') {
      setVisuallySelectedOption(currentOption => {
        if (currentOption == null) return 0;

        const nextOption = getNextValidOption(currentOption, options);

        return nextOption;
      });
    }

    const isValidSelectKeyboardKey = e.key === 'Enter' || e.key === ' ' || e.key === 'Tab';
    const dismissSelectKeyboardKey = e.key === 'Escape';

    if (isValidSelectKeyboardKey || dismissSelectKeyboardKey) {
      e.preventDefault();
      setIsOpen(false);
      if (btnRef.current) btnRef.current.focus();
    }

    if (dismissSelectKeyboardKey) {
      setVisuallySelectedOption(actualSelectedOption);
    }

    if (isValidSelectKeyboardKey) {
      setActualSelectedOption(visuallySelectedOption);

      if (visuallySelectedOption !== actualSelectedOption) {
        onChange?.(visuallySelectedOption);
      }
    }
  };

  const labelJsx = (
    <span>
      {label}
      {required ? <span className={s9(c.required)}>*</span> : null}
    </span>
  );

  return options.length > 0 ? (
    <DropdownWrapper styleExtend={styleExtend}>
      <div
        className={s9(
          c.base,
          c[variantClass],
          isOpen && c.zIndexDropdown,
          isInvalid && !isOpen && c.isInvalid
        )}
        style={inlineStyle}
        ref={ref}
        {...attrs}
      >
        {label && actualSelectedOption != null && (
          <div className={s9(c.labelWrapper)}>
            <label htmlFor={buttonId} className={s9(c.label, disabled && c.disabled)}>
              {labelJsx}
            </label>
          </div>
        )}
        <button
          id={buttonId}
          className={s9(c.btn, disabled && c.disabled, ...selectStyleExtend)}
          onClick={onClick}
          role="combobox"
          aria-controls={optionsWrapperId}
          aria-expanded={isOpen}
          aria-labelledby={label ? labelId : undefined}
          type="button"
          data-testid="dropdown-btn"
          ref={btnRef}
          onKeyDown={isKeyboardControlled ? undefined : btnOnKeyDown}
          disabled={disabled}
        >
          {actualSelectedOption != null
            ? options[actualSelectedOption]?.text
            : labelJsx || options[0].text}
          <Icon icon="chevron" styleExtend={[c.icon]} />
        </button>
        {isOpen && (
          <div
            className={s9(c.optionsWrapper, ...optionsWrapperStyleExtend)}
            ref={optionsWrapperRefCb}
            onKeyDown={isKeyboardControlled ? undefined : optionsWrapperKeyDown}
            style={{ ...dropdownPosition }}
            role="listbox"
            aria-labelledby={optionsWrapperId}
            aria-activedescendant={`dropdown-option-${actualSelectedOption}`}
            tabIndex={-1}
            onBlur={optionsWrapperOnBlur}
            data-testid="dropdown-options-wrapper"
          >
            {options.map(({ text, disabled: disabledOption }, i) => (
              <DropdownOption
                text={text}
                setActualSelectedOption={setActualSelectedOption}
                setVisuallySelectedOption={setVisuallySelectedOption}
                setIsOpen={setIsOpen}
                currentOption={i}
                selectedOption={visuallySelectedOption}
                key={text}
                disabled={disabledOption}
                isLastOption={i === options.length - 1}
                actualSelectedOption={actualSelectedOption}
                variant={variant}
                onChange={onChange}
              />
            ))}
          </div>
        )}
      </div>
      {description ? (
        <FormfieldDescription isInvalid={isInvalid} id={descriptionId}>
          {description}
        </FormfieldDescription>
      ) : null}
    </DropdownWrapper>
  ) : null;
});

export default Dropdown;
export const DropdownWithMemo = React.memo(Dropdown);

function DropdownWrapper({
  styleExtend,
  children,
}: React.PropsWithChildren<{ styleExtend: StyleExtend }>) {
  return <div className={s9(...styleExtend)}>{children}</div>;
}

///////////////
//  HELPERS  //
///////////////
function getDropdownPositionForOverlap({ btnElement, selectedOption }: GetDropdownPositionArgs) {
  return { top: -(btnElement.clientHeight * selectedOption) };
}

function getDropdownReposition(
  rect: DOMRect,
  dropdownOptionHeight?: number
): React.CSSProperties | null {
  const enoughSpaceBottom = window.innerHeight > rect.bottom + maxAllowedOffsetFromViewPort;
  const enoughSpaceTop = rect.top >= maxAllowedOffsetFromViewPort;
  const notOutOfBound = rect.top > maxAllowedOffsetFromViewPort;
  const dynamicPlacement = enoughSpaceBottom && notOutOfBound;
  const topPlacement = enoughSpaceTop && notOutOfBound;

  // First it tries to reposition in a way that the selected option will overlap with the dropdown button
  if (dynamicPlacement) {
    return null;
  }

  // If can't, it tries to reposition the dropdown above
  if (topPlacement) {
    return { bottom: 0 };
  }

  // Afterwards, it determines wether to keep it on top or if it's out of bound.
  // If it's out of bound, it sets max height of the dropdown so it can fit in the viewport (bottom placement).
  // Some of the calculations below might seem counterintuitive as they're based on when the dropdown is
  // actually out of bound on top placement.
  const spaceLeftBelow = window.innerHeight - rect.bottom;
  const notEnoughSpaceForDropdown = rect.height > spaceLeftBelow;

  return {
    top: 0,
    ...(notEnoughSpaceForDropdown
      ? {
          maxHeight: `${Math.floor(
            spaceLeftBelow + (dropdownOptionHeight || 0) - maxAllowedOffsetFromViewPort
          )}px`,
        }
      : {}),
  };
}

function getNextValidOption(currentOption: number, options: Option[]) {
  if (currentOption === options.length - 1) return currentOption;

  let nextOption = currentOption + 1;

  while (options[nextOption].disabled) {
    if (nextOption === options.length - 1) return currentOption;
    nextOption += 1;
  }

  return nextOption;
}

function getPrevValidOption(currentOption: number, options: Option[]) {
  let prevOption = currentOption - 1;
  if (currentOption === 0) return currentOption;

  while (options[prevOption].disabled) {
    if (prevOption === 0) return currentOption;
    prevOption -= 1;
  }

  return prevOption;
}
