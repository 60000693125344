'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import HtzImage from '@haaretz/s-htz-image';
import s9 from 'style9';

import type { SpecialSaleDataFragment } from '@haaretz/s-fragments/SpecialSaleData';

const c = s9.create({
  specialOffers: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    maxWidth: space(200),
    width: '100%',
    fontWeight: 700,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    columnGap: space(8),
    ...merge(
      mq({
        until: 'l',
        value: {
          rowGap: space(8),
          gridTemplateColumns: 'auto',
          gridTemplateRows: 'auto auto',
        },
      })
    ),
  },
  specialOffersWrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `radial-gradient(circle at 0 0, ${color('ppSpecialOfferBgGStart')}, ${color(
      'ppSpecialOfferBgGEnd'
    )})` as string,
    marginTop: space(-25),
    marginBottom: space(-50),
    paddingTop: space(30),
    paddingBottom: space(60),
    zIndex: '-1',
  },
  specialOffersWrapperLoggedIn: {
    marginTop: space(-35),
    paddingTop: space(45),
  },
  specialOffersWrapperWithToggle: {
    marginBottom: space(-70),
    paddingBottom: space(70),
  },
  specialOffersExclusive: {
    ...typesetter(1),
    ...merge(
      mq({
        until: 'l',
        value: {
          order: -1,
          ...typesetter(-2),
        },
      })
    ),
  },
  specialOffersTexts: {
    ...merge(
      mq({
        until: 'l',
        value: {
          order: 1,
          textAlign: 'center',
        },
      })
    ),
  },
  specialOffersTitle: {
    ...typesetter(5),
  },
  specialOffersImage: {
    width: '326px',
    ...merge(
      mq({
        until: 'l',
        value: {
          marginInlineStart: 'auto',
          marginInlineEnd: 'auto',
          width: '127px',
        },
      })
    ),
  },
});

interface SpecialOffersProps extends SpecialSaleDataFragment {
  showToggle: boolean;
}
export default function SpecialOffers({ exclusive, title, image, showToggle }: SpecialOffersProps) {
  const user = useUser('cookieValue');

  return (
    <div
      className={s9(
        c.specialOffersWrapper,
        user?.isLoggedIn && c.specialOffersWrapperLoggedIn,
        showToggle && c.specialOffersWrapperWithToggle
      )}
      data-testid="special-offer-section"
    >
      <div className={s9(c.specialOffers)}>
        <div className={s9(c.specialOffersTexts)}>
          <div className={s9(c.specialOffersExclusive)} data-testid="special-offer-exclusive">
            {exclusive}
          </div>
          <div className={s9(c.specialOffersTitle)} data-testid="special-offer-title">
            {title}
          </div>
        </div>
        {image ? (
          <HtzImage
            {...image}
            styleExtend={[c.specialOffersImage]}
            imgData={image.files[0]}
            aspect="full"
            widths={[127, 254, 326]}
            data-testid="special-offer-img"
          />
        ) : null}
      </div>
    </div>
  );
}
