import getCommonParameters from '../getCommonParameters';
import getPurchaseServiceLink from '../getPurchaseServiceLink';

import type { CommonPaymentParameters } from '../getCommonParameters';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';

export type GetCouponLinkProps = CommonPaymentParameters &
  (
    | (Required<Pick<PageDataFragment, 'personalCouponId'>> & {
        couponId?: never;
        personalCouponId: PageDataFragment['personalCouponId'];
        token?: never;
      })
    | (Required<Pick<PageDataFragment, 'couponId'>> & {
        couponId: PageDataFragment['couponId'];
        personalCouponId?: never;
        token: string;
      })
  ) & {
    msPurchaseUrl: string;
  };
export default function getCouponLink({
  msPurchaseUrl,
  couponId,
  personalCouponId,
  token,
  ...props
}: GetCouponLinkProps) {
  const searchParams = getCommonParameters(props);

  /* istanbul ignore else */
  if (personalCouponId) {
    searchParams.append('personalCouponId', personalCouponId);
  } else if (couponId && token) {
    searchParams.append('couponId', couponId);
    searchParams.append('token', token);
  }

  return getPurchaseServiceLink({ msPurchaseUrl, searchParams, endpoint: 'okCoupon' });
}
