'use client';

import border from '@haaretz/l-border.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Button, { ButtonProps } from '@haaretz/s-button';
import RichText from '@haaretz/s-rich-text';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import s9 from 'style9';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const c = s9.create({
  base: {
    marginBottom: space(3),
    paddingRight: space(6),
    paddingLeft: space(6),
    position: 'relative',
    zIndex: zIndex('above'),

    ...merge(
      {
        ...border({
          width: 1,
          spacing: 2,
          style: 'solid',
          side: 'block',
          color: 'transparent',
        }),
      },
      { ...typesetter(0, { lines: 5 }) },
      mq({
        from: 'xl',
        until: 'xxl',
        value: { ...typesetter(-1, { lines: 5 }) },
      }),
      mq({ from: 'xxl', value: { ...typesetter(-2, { lines: 5 }) } })
    ),
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  line2: {
    fontWeight: 400,
  },
});

export interface OfferButtonProps {
  /**
   * the first line of the purchase button
   */
  line1: PurchaseOfferFragment['line1Text'];
  /**
   * the second line of the purchase button
   */
  line2: PurchaseOfferFragment['line2Text'];
  onClick?: ButtonProps<'button'>['onClick'];
  state?: ButtonProps<'button'>['state'];
}

export default function OfferButton({ line1: [line1], line2, onClick, state }: OfferButtonProps) {
  if (!isHtmlNode(line1)) return null;

  const { content: line1Content } = line1;
  const line2Content = line2?.[0] && isHtmlNode(line2[0]) ? line2[0].content : null;

  return (
    <Button
      data-testid="offer-btn"
      variant="sales"
      styleExtend={[c.base]}
      stretch
      onClick={onClick}
      {...(state === 'busy' ? { state, busyNotice: '' } : { state })}
    >
      <span className={s9(c.textWrapper)}>
        <RichText as="span" content={line1Content} />
        {line2Content ? (
          <RichText as="span" content={line2Content} styleExtend={[c.line2]} />
        ) : null}
      </span>
    </Button>
  );
}
