interface GetPaymentLinkProps {
  msPurchaseUrl: string;
  searchParams: URLSearchParams;
  endpoint: 'cgPayment' | 'savePurchasePreferences' | 'okCoupon' | 'changePlan';
}

export default function getPurchaseServiceLink({
  endpoint,
  msPurchaseUrl,
  searchParams,
}: GetPaymentLinkProps) {
  if (!msPurchaseUrl) {
    throw new Error('A URL for the purchase microservice is required');
  }

  return `${msPurchaseUrl}/${endpoint}?${searchParams.toString()}`;
}
