import useUser from '@haaretz/s-atoms/user';
import getCouponLink from '@haaretz/s-pp-build-promotions-link/getCouponLink';
import generatePath from '@haaretz/s-pp-routing-utils/generatePath';
import { useRouter } from 'next/router';
import React from 'react';

import useFreeProductOrCoupon from '../useFreeProductOrCoupon';

import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { GetCouponLinkProps } from '@haaretz/s-pp-build-promotions-link/getCouponLink';

type RedeemPersonalCouponProps = GetCouponLinkProps & {
  personalCouponId: NonNullable<PageDataFragment['personalCouponId']>;
  couponId?: never;
  token?: never;
  nextStepName: PurchasePageSteps;
};

export default function usePersonalFreeCoupon() {
  const router = useRouter();
  const redeemFreeCoupon = useFreeProductOrCoupon();
  const user = useUser('cookieValue');

  const redeemPersonalCoupon = React.useCallback(
    ({ nextStepName, ...props }: RedeemPersonalCouponProps) => {
      if (user?.isLoggedIn === true) {
        const couponUrl = getCouponLink(props);

        return redeemFreeCoupon(couponUrl, nextStepName);
      } else {
        const { pathName, asPath } = generatePath({
          nextStepName: 'login',
          asPath: router.asPath,
          currentQueryParams: router.query,
        });

        router.push(pathName, asPath, { shallow: true });
        return Promise.resolve();
      }
    },
    [redeemFreeCoupon, router, user?.isLoggedIn]
  );

  return redeemPersonalCoupon;
}
