'use client';

import abTest from '@haaretz/l-ab-test.macro';
import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import BlockLink from '@haaretz/s-block-link';
import Card from '@haaretz/s-card';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import usePpAbTest from '@haaretz/s-use-pp-ab-test';
import usePpOfferCardOperations from '@haaretz/s-use-pp-offer-card-operations';
import * as React from 'react';
import s9 from 'style9';

import OfferBenefits from '../components/OfferBenefits';
import OfferNotice from '../components/OfferNotice';

import CancellableLine from './components/CancellableLine';
import OfferButton from './components/OfferButton';
import OfferTitle from './components/OfferTitle';
import PriceDetails from './components/PriceDetails';

import type { BiDataOverrides, RequiredSelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';
import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  base: {
    maxWidth: space(100),
    position: 'relative',
    width: '100%',
    overflow: 'visible',
    ...merge(
      {
        ...mq({
          until: 'm',
          value: {
            paddingTop: space(7),
            paddingBottom: space(7),
            paddingInlineStart: space(6),
            paddingInlineEnd: space(6),
          },
        }),
      },
      {
        ...mq({
          from: 'm',
          value: {
            paddingTop: space(8),
            paddingBottom: space(8),
            paddingInlineStart: space(8),
            paddingInlineEnd: space(8),
          },
        }),
      }
    ),
  },
  notice: {
    position: 'absolute',
    top: space(3),
  },
  bestOffer: {
    backgroundColor: color('bestOfferOfferBg'),
    ...border({
      color: color('primary1000'),
      style: 'solid',
      width: '1px',
      spacing: 6,
      side: 'all',
    }),

    ...mq({ until: 'l', value: { marginTop: space(3) } }),
  },
  bestOfferContent: {
    ':before': {
      content: 'var(--ofr-cntnt)',
      position: 'absolute',
      top: 0,
      left: '50%',
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: color('primary1000'),
      transform: 'translate(-50%, -50%)',
      color: color('neutral100'),
      height: space(7),
      paddingTop: space(1),
      paddingBottom: space(1),
      paddingRight: space(4),
      paddingLeft: space(4),
      borderRadius: radius('xxLarge'),
      ...typesetter(-2),

      ...merge(
        mq({
          from: 'xxl',
          value: {
            height: space(8),
            paddingRight: space(5),
            paddingLeft: space(5),
            ...typesetter(-3),
          },
        })
      ),
    },
  },
  cardHeader: {
    marginBottom: space(4),
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeaderWithSubtitle: {
    marginBottom: space(4),

    ...mq({ from: 's', value: { marginBottom: space(5) } }),
  },
  subTitle: {
    ...typesetter(-2),

    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
});

export interface OffersCardProps {
  benefits?: PurchaseOfferFragment['benefits'];
  benefitsHeading?: PurchaseOfferFragment['benefitsHeading'];
  contracted?: boolean;
  couponId?: PageDataFragment['couponId'];
  exclusive?: PurchaseOfferFragment['exclusive'];
  exclusiveStrip?: PurchaseOfferFragment['exclusiveStrip'];
  legalText: PurchaseOfferFragment['legalText'];
  line1Text: PurchaseOfferFragment['line1Text'];
  line2Text?: PurchaseOfferFragment['line2Text'];
  note?: PurchaseOfferFragment['note'];
  offerData: RequiredSelectedOfferProps;
  offerType: PurchaseOfferFragment['offerType'];
  personalCouponId?: PageDataFragment['personalCouponId'];
  product: PurchaseOfferFragment['product'];
  productName: PurchaseOfferFragment['productName'];
  designVersion?: PageDataFragment['designVersion'];
  steps: StepDataFragment[];
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
}

const sentImpressions: string[] = [];

export default function OfferCardVersionTwo({
  benefits,
  benefitsHeading,
  biData,
  contracted,
  couponId,
  exclusive,
  exclusiveStrip,
  inlineStyle,
  legalText,
  line1Text,
  line2Text,
  offerData,
  offerType,
  personalCouponId,
  product,
  productName,
  steps,
  designVersion,
  styleExtend = [],
}: OffersCardProps) {
  const user = useUser('serverValue');
  const offerCardRef = React.useRef<HTMLAnchorElement>(null);
  const abTestData = usePpAbTest();

  const onClickAndImpressionBiData: BiDataOverrides = {
    ...biData,
    prod_no: offerData.productNumber,
    sale_code: offerData.saleCode,
    promotions_no: offerData.promotionNumber,
    campaign_details: `title: ${productName}${
      isHtmlNode(line1Text[0])
        ? `, button: ${line1Text[0].content} ${
            line2Text?.[0] && isHtmlNode(line2Text[0]) ? line2Text[0].content : ''
          }`
        : ''
    }`,
  };

  const { asPath, enabledPpQuery, onClick } = usePpOfferCardOperations({
    offerData,
    onClickAndImpressionBiData: {
      ...onClickAndImpressionBiData,
      action_id: 21,
    },
    steps,
    couponId,
    personalCouponId,
    offerType,
  });

  const impressionKey = offerData.contentId + (user?.userMail || '');

  useImpressionObserver({
    disabled: sentImpressions.includes(impressionKey),
    elementRef: offerCardRef,
    biData: onClickAndImpressionBiData,
    onObserve: () => {
      sentImpressions.push(impressionKey);
    },
  });
  return (
    <Card
      elevationLevel={1}
      inlineStyle={{ ...inlineStyle, '--ofr-cntnt': `"${exclusiveStrip}"` }}
      styleExtend={[
        c.base,
        !!exclusiveStrip && c.bestOffer,
        !!exclusiveStrip && c.bestOfferContent,
        ...styleExtend,
      ]}
      data-testid="offer-card"
      id={offerData.contentId}
    >
      {exclusive && <OfferNotice styleExtend={[c.notice]}>{exclusive}</OfferNotice>}
      <header className={s9(c.cardHeader, !!benefitsHeading && c.cardHeaderWithSubtitle)}>
        <OfferTitle product={product} productName={productName} />
        {!!benefitsHeading && <span className={s9(c.subTitle)}>{benefitsHeading}</span>}
      </header>
      <PriceDetails line1={line1Text} line2={line2Text} />
      <OfferButton onClick={onClick} state={enabledPpQuery ? 'busy' : undefined} />
      {abTest('benefits on offer', '2024-08-20', abTestData, {
        A: () => {
          return null;
        },
        B: () => {
          return (
            <OfferBenefits
              items={benefits}
              heading={benefitsHeading}
              contracted={contracted}
              designVersion={designVersion}
              biData={
                biData && {
                  ...biData,
                  ...(isHtmlNode(line1Text[0])
                    ? {
                        campaign_details: `${`button: ${line1Text[0].content} ${
                          line2Text?.[0] && isHtmlNode(line2Text[0]) ? line2Text[0].content : ''
                        }`}`,
                      }
                    : {}),
                }
              }
            />
          );
        },
      })()}

      <CancellableLine
        legalText={legalText}
        biData={biData}
        onTermsButtonClick={onClick}
        loading={enabledPpQuery}
      />
      <BlockLink
        aria-hidden
        tabIndex={-1}
        href={asPath}
        shallow
        onClick={onClick}
        ref={offerCardRef}
      >
        {''}
      </BlockLink>
    </Card>
  );
}
